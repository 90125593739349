import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "../atoms/Button/Index";

const UserTypeModal = ({ handleClose, show, handleSubmit }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column'>
            <span>If you change your user type, your existing subscription will be cancelled and you have to buy a new subscription.</span>
            <span>Are you sure you want to proceed?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} text="No" />
          <Button variant="primary" type="submit" text="Yes" onClick={handleSubmit} />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserTypeModal;
