import React from 'react';
import { Navigate } from 'react-router-dom';

const UnAuthorizedRoute = ({ children }) => {
    const { usertype, eventsAccessAllowed, gymReviewsAllowed, discountsAllowed } = localStorage.getItem("subscription") ? JSON.parse(localStorage.getItem("subscription")) : "";

    if (usertype !== 'COACH') {
        if (!eventsAccessAllowed || !gymReviewsAllowed || !discountsAllowed) {
            return <Navigate to="/not-authorized" replace />;
        }
    }

    return children;
};

export default UnAuthorizedRoute;