import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../atoms/Button/BackButton';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import icInfo from '../assets/img/ic-info.svg';
import icHeart from '../assets/img/ic-heart.svg';
import icHeartFilled from '../assets/img/heart-fill.svg';
import formatDate from '../config/formateDate';
import Spinner from '../atoms/Spinner';
import toaster from '../config/toaster';
import { ToastContainer } from 'react-toastify';

const EventDetails = () => {
  let { id } = useParams();
  const [event, setEvent] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [favoriteLoading, setFavoriteLoading] = useState(true);

  const fetchEvent = async () => {
    let result = await axiosWrapper.get(`${endPoint.host}/event/${id}`, endPoint.header);
    if (result.success) {
      setEvent(result.data);
      setLoading(false);
      setFavoriteLoading(false);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  const onAddToFavorite = async () => {
    setLoading(true);
    const postData = {
      isFavorite: event.isFavorite ? false : true,
    }
    let result = await axiosWrapper.updatePatch(`${endPoint.host}/event/${id}/favorite`, postData, endPoint.header);
    if (result.success) {
      toaster(`${postData.isFavorite ? 'success' : 'error'}`, `Event ${postData.isFavorite ? 'added to' : 'removed from'} favorite`);
      fetchEvent();
    }
  }

  if (isLoading) {
    return <Spinner />;
  }
  else return (
    <>
      <ToastContainer />
      <section className='pt-40px pb-4'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex align-items-center justify-content-between mb-5">
              <BackButton />
            </div>
            <div className="col-lg-11">
              <div className="title d-flex justify-content-between align-items-center">
                <div>
                  <img src={icInfo} className="img-fluid" alt="info icon" />
                </div>
                <div>
                  <h3 className="heading text-center font-size-32px">{event.name}</h3>
                  <p className="text-center">
                    {event.organisers?.map((item) => {
                      return ` ${item},`;
                    })}
                  </p>
                </div>
                <div>
                  {favoriteLoading ? <Spinner /> :
                    <img
                      src={event.isFavorite ? icHeartFilled : icHeart}
                      className="img-fluid cursor-pointer"
                      style={{ width: '40px' }}
                      alt="favorite icon" onClick={onAddToFavorite}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-11">
              <div className="row justify-content-center serviceBoxes">
                <div className="col-lg-12">
                  <div className="card card-custom border-0 p-0">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-lg-5">
                          <img src={endPoint.url + event.image} width="400px" alt={event.name} />
                        </div>
                        <div className="col-lg-1" />
                        <div className="col-lg-6 d-flex flex-column gap-1">
                          <div className='row'>
                            <div className='col-md-4'>
                              <span className="font-size-24px font-bold">Location</span>
                            </div>
                            <div className='col-md-8'>
                              <div className="text-end font-size-20px d-flex flex-column gap-1">
                                <span>{event.address}</span>
                                <span>{event.city}</span>
                                <span>{event.postalCode}</span>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-4'>
                              <span className="font-size-24px font-bold">Start Time</span>
                            </div>
                            <div className='col-md-8'>
                              <div className="text-end font-size-20px d-flex flex-column">
                                <span className="text-end font-size-20px">{formatDate(event.startTime, "MMMM Do YYYY, h:mm:ss a")}</span>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-4'>
                              <span className="font-size-24px font-bold">End Time</span>
                            </div>
                            <div className='col-md-8'>
                              <div className="text-end font-size-20px d-flex flex-column">
                                <span className="text-end font-size-20px">{formatDate(event.endTime, "MMMM Do YYYY, h:mm:ss a")}</span>
                              </div>
                            </div>
                          </div>

                          <div className='d-flex flex-column justify-content-start align-items-start'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <span className="font-size-24px font-bold">Additional Notes</span>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className="text-end font-size-20px d-flex flex-column">
                                  <span className="text-start font-size-20px">{event.notes}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="featured pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="title">
                <h3 className="heading text-center font-size-40px">About</h3>
              </div>
              <p style={{ whiteSpace: 'pre-line' }} >{event.description}</p>

              <p className="text-center mt-100px">
                <a href={event.url} className="btn btn-primary btn-pill color-primary">
                  Get tickets
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventDetails;
