import React from 'react';
import filledStar from '../../assets/img/icons/ic-filled-star.svg';
import Star from '../../assets/img/icons/ic-star.svg';
const Rating = (props) => {
  const { rating } = props;
  return (
    <>
      {rating === 0 ? (
        <span>
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
        </span>
      ) : rating === 1 ? (
        <span>
          <img src={filledStar} alt="partial star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
        </span>
      ) : rating >= 2 && rating < 3 ? (
        <span>
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
        </span>
      ) : rating === 3 ? (
        <span>
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={Star} alt="full star" />
          <img src={Star} alt="full star" />
        </span>
      ) : rating === 4 ? (
        <span>
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={Star} alt="full star" />
        </span>
      ) : (
        <span>
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
          <img src={filledStar} alt="partial star" />
        </span>
      )}
    </>
  );
};

export default Rating;
