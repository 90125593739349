import React from 'react';
import { NavLink } from 'react-router-dom';

const Button = (props) => {
  const { type, icon, text, variant, link, onClick, disabled=false } = props;
  return (
    <>
      {link ? (
        <NavLink to={link}>
          <button type={type} className={`btn btn-${variant}`}>
            {text}
          </button>
        </NavLink>
      ) : (
        <button onClick={onClick} type={type} className={`btn btn-${variant}`} disabled={disabled}>
          {icon && <i className={icon}></i>}
          {text}
        </button>
      )}
    </>
  );
};

export default Button;
