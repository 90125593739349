import React, { useState } from 'react';
import Button from '../../atoms/Button/Index';
import ReactStars from 'react-rating-stars-component';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import { useNavigate, useParams } from 'react-router-dom';
import Snackbar from '../../atoms/Snackbar/Index';
import { ToastContainer } from 'react-toastify';
import toaster from '../../config/toaster';

const SubmitReview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [comment, setComment] = useState('');
    const [valueMoneyRating, setValueMoneyRating] = useState(0);
    const [professionalismRating, setProfessionalismRating] = useState(0);
    const [supportRating, setSupportRating] = useState(0);
    const [responseTimeRating, setResponseTimeRating] = useState(0);
    const [availabilityRating, setAvailabilityRating] = useState(0);
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(comment);
        if (comment === '') {
            toaster('error', "Fields cannot be empty")
            return false;
        }
        let url = endPoint.host + '/' + id + '/review';
        let result = await axiosWrapper.post(
            url,
            {
                valueMoneyRating,
                professionalismRating,
                supportRating,
                responseTimeRating,
                availabilityRating,
                comment,
            },
            endPoint.header
        );

        if (result.success) {
            toaster('success', result.message)
            setComment('');
            navigate(0);
        }
    };
    return (
        <>
            <ToastContainer />

            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="mb-3">Value Money Rating</label>
                        <ReactStars
                            count={5}
                            onChange={(newRating) => setValueMoneyRating(newRating)}
                            size={30}
                            activeColor="#ffd700"
                        />
                    </div>
                    <div className="form-group">
                        <label className="mb-3">Professionalism Rating</label>
                        <ReactStars
                            count={5}
                            onChange={(newRating) => setProfessionalismRating(newRating)}
                            size={30}
                            activeColor="#ffd700"
                        />
                    </div>
                    <div className="form-group">
                        <label className="mb-3">Support Rating</label>
                        <ReactStars
                            count={5}
                            onChange={(newRating) => setSupportRating(newRating)}
                            size={30}
                            activeColor="#ffd700"
                        />
                    </div>
                    <div className="form-group">
                        <label className="mb-3">Response Time Rating</label>
                        <ReactStars
                            count={5}
                            onChange={(newRating) => setResponseTimeRating(newRating)}
                            size={30}
                            activeColor="#ffd700"
                        />
                    </div>
                    <div className="form-group">
                        <label className="mb-3">Availability Rating</label>

                        <ReactStars
                            count={5}
                            onChange={(newRating) => setAvailabilityRating(newRating)}
                            size={30}
                            activeColor="#ffd700"
                        />
                    </div>

                    <div className="form-group">
                        <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Comment..."
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <Button variant="primary btn-pill" text="Submit" type="submit" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default SubmitReview;
