import React, { useEffect, useState } from 'react';
import Button from '../../atoms/Button/Index';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import RangeSlider from 'react-bootstrap-range-slider';
import toaster from '../../config/toaster';
import { ToastContainer } from 'react-toastify';

const TrainerSearchFilter = (props) => {
    const { handleGymList } = props;
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setLocation] = useState('');
    const [values, setValues] = useState({
        location: '',
        rating: 0,
    });
    const [price, setPrice] = useState(0);
    const [clearVisible, setClearVisible] = useState(false);


    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    };

    const onLocationChanged = e => {
        const { value } = e.target;
        locations.forEach(l => {
            if (l.name === value) {
                l.selected = true;
            } else {
                l.selected = false;
            }
        });
        setLocations([...locations]);
        setLocation(value);
    }

    const fetchLocations = async () => {
        let result = await axiosWrapper.get(`${endPoint.host}/locations/trainers`, endPoint.header);
        if (result.success) {
            let locationData = [];
            result.data.length > 0 && result.data.map((location) => {
                locationData.push({
                    name: location,
                    selected: false
                });
            })
            setLocations(locationData);
        }
    }

    const onResetLocation = () => {
        locations.forEach(l => {
            l.selected = false;
        });
        setLocations([...locations]);
        setLocation('');
    }

    const onClearFilters = async (e) => {
        e.preventDefault();
        setClearVisible(false);
        setValues({
            ...values,
            location: '',
            rating: 0
        });
        onResetLocation();
        setPrice(0);
        let result = await axiosWrapper.get(
            `${endPoint.host}/trainers?limit=10`,
            endPoint.header
        );

        if (result.success) {
            handleGymList(result.data.trainers, false, result.data.total);
        }
    }

    const handleFilter = async (e, type = "") => {
        e?.preventDefault();
        setClearVisible(true);
        // if (selectedLocation === '') {
        //     toaster("error", "Please select location first");
        //     return false
        // } 
        // else if (values.rating === 0) {
        //     toaster("error", "Please select rating first");
        //     return false
        // }
        let result = await axiosWrapper.get(
            `${endPoint.host}/trainers?price=${price}&location=${selectedLocation}&rating=${values.rating}&limit=10`,
            endPoint.header
        );

        if (result.success) {
            handleGymList(result.data.trainers, false, result.data.total)
        }
    };

    const isDisabled = () => {
        if (selectedLocation === '' && values.rating === 0 && price === 0) {
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        isDisabled();
    }, [selectedLocation, values.rating, price, locations])


    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="col-lg-11 mt-80px">
                <form onSubmit={handleFilter}>
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="select-wrapper selectLocation">
                                <select onChange={onLocationChanged} className="form-control form-control-pill text-center h-48px">
                                    <option>Select Location</option>
                                    {locations && locations.map((item, index) => {
                                        return (

                                            <option key={index} value={item.name} selected={item.selected ? true : false}>{item.name}</option>

                                        )
                                    })}

                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="select-wrapper rating">

                                <select
                                    onChange={handleChange('rating', 0)}
                                    className="form-control form-control-pill text-center h-48px"
                                    defaultValue={values.rating}
                                >
                                    <option value="" selected={values.rating === 0}>Rating</option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3 text-center">
                            <label htmlFor="price">Price (per month)</label>
                            <RangeSlider
                                value={price}
                                max={1500}
                                onChange={(changeEvent) => setPrice(changeEvent.target.value)}
                            />
                            <div>££- £££</div>
                        </div>
                        <div className="col-lg-3 text-center">
                            <Button
                                type="submit"
                                text="Apply filters"
                                variant="tr color-text-dark font-size-20px"
                                disabled={isDisabled()}
                            />
                            {clearVisible && <Button
                                type="button"
                                text="Clear filters"
                                variant="tr color-text-dark font-size-20px"
                                onClick={onClearFilters}
                            />
                            }

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default TrainerSearchFilter;