 let endPoint = {
  // test server
  // host: "http://3.75.222.61:3000/api/user",
  // url: "http://3.75.222.61:3000/",
  // live server
  host: "https://pro-athelite.com/pro_athelite_server/api/user",
  url: "https://pro-athelite.com/pro_athelite_server/",
  header: {
    Authorization: localStorage.getItem("token")
  },
  locationApiKey: process.env.REACT_APP_MAP_API
}

export default endPoint;
