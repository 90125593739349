import React, { useEffect, useState } from 'react';
import heartIcon from '../../assets/img/ic-heart.svg';
import placeholder from '../../assets/img/placeholder.svg';
import shieldIcon from '../../assets/img/icons/shield.svg';
import icInfo from '../../assets/img/ic-info.svg';
import downArrow from '../../assets/img/ic-down.svg';
import Carousel from 'react-elastic-carousel';
import fbIcon from '../../assets/img/icons/fb.svg';
import linkedinIcon from '../../assets/img/icons/linkedin.svg';
import instaIcon from '../../assets/img/icons/insta.svg';
import twitterIcon from '../../assets/img/icons/twitter.svg';
import BackButton from '../../atoms/Button/BackButton';
import Button from '../../atoms/Button/Index';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import Spinner from '../../atoms/Spinner/index';
import RatingComponent from '../../config/rating';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import UserTypeModal from '../../popups/UserTypeModal';

const StyledImage = styled.img`
  width: 180px;
  height: 180px;
  background-size: cover;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;


export const userTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  COACH: 'COACH',
}


const Profile = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
  ];

  const [profile, setProfile] = useState({});
  const [showRating, setShowRating] = useState(false);
  const [userTypeModelVisible, setUserTypeModelVisible] = useState(false);

  const fetchProfile = async () => {
    setLoading(true);
    let result = await axiosWrapper.get(`${endPoint.host}/me`, endPoint.header);
    if (result.success) {
      console.log('result.data', result.data);
      setProfile(result.data);
      setLoading(false);
    }
  };

  const { isEnhancedProfile, _id } = localStorage.getItem("subscription") ? JSON.parse(localStorage.getItem("subscription")) : "";
  const usertype = localStorage.getItem("usertype") ? localStorage.getItem("usertype") : "";

  const onNavigate = () => {
    if (usertype === userTypes.INDIVIDUAL) {
      navigate('/subscription-individual');
    } else if (usertype === userTypes.COACH) {
      navigate('/subscription-coach');
    }
  }
  const onNavigateToSubscription = () => {
    if (usertype === userTypes.INDIVIDUAL) {
      navigate({
        pathname: '/subscription-coach',
        state: { userType: userTypes.COACH }
      });
    } else if (usertype === userTypes.COACH) {
      navigate({
        pathname: '/subscription-individual',
        state: { userType: userTypes.INDIVIDUAL }
      });
    }
  }
  useEffect(() => {
    fetchProfile();
  }, []);
  const onChangeUserType = () => setUserTypeModelVisible(true);
  return (
    <>
      {loading ? (
        <div style={{ padding: '100px' }}>
          <Spinner />
        </div>
      ) : (
        <>
          <section className='pt-40px pb-0'>
            <div className="container">
              <div className="row justify-content-center">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <BackButton />
                  <Button text="Edit Profile" link="edit" variant="primary btn-pill" />
                </div>
                <div className="col-lg-11">
                  <div className="title d-flex justify-content-between align-items-center">
                    <div>
                      <img alt="Info" src={icInfo} className="img-fluid" />
                    </div>
                    <div>
                      <h3 className="heading text-center">
                        <span>
                          <img alt="Sheild" src={shieldIcon} />
                        </span>
                        {profile?.fullname}
                      </h3>
                      <p className="text-center">{profile?.company}</p>
                    </div>
                    <div>
                      {/* <img src={heartIcon} alt="Favorite" className="img-fluid" /> */}
                      <Button type="button" variant="primary" onClick={() => navigate('/favorites')} text="Favorites" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 mb-100px">
                  <div className="row justify-content-center serviceBoxes">
                    <div className="col-lg-12">
                      <div className="card card-custom border-0 p-0">
                        <div className="card-body p-0">
                          <div className="row">
                            <div className="col-lg-5">
                              <img alt="Profile Pic" src={profile.profilePic ? endPoint.url + profile.profilePic : placeholder} width="400px" />
                            </div>
                            <div className="col-lg-1" />
                            <div className="col-lg-6">
                              {isEnhancedProfile ? (
                                <p className="text-start">{profile?.description}</p>
                              ) : (
                                ''
                              )}

                              <table className="table table-borderless">
                                <tbody>
                                  {usertype === 'INDIVIDUAL' ?
                                    <>
                                      <tr>
                                        <th className="font-size-24px">Email</th>
                                        <td className="text-end font-size-20px">{profile.email}</td>
                                      </tr>
                                      <tr>
                                        <th className="font-size-24px">Phone</th>
                                        <td className="text-end font-size-20px">{profile.phone}</td>
                                      </tr>
                                    </>
                                    :
                                    <>
                                      <tr>
                                        <th className="font-size-24px">
                                          <div id="rating_toggle">Rating
                                            <span>
                                              <img alt="arrow down" onClick={() => setShowRating(!showRating)} src={downArrow} className="img-fluid" />
                                            </span>
                                          </div>
                                        </th>

                                        <td className="text-end font-size-20px">
                                          <RatingComponent ratingCount={profile.ratingCount} />
                                        </td>
                                      </tr>

                                      {showRating && (
                                        <tr style={{ display: "table-row" }}>
                                          <td colSpan="2">
                                            <div className="bg-light p-3">
                                              <table className="table table-sm table-borderless align-middle m-0">
                                                <tbody><tr>
                                                  <th className="font-size-14px">Overall</th>
                                                  <td className="text-end font-size-20px">

                                                  </td>
                                                </tr>
                                                  <tr>
                                                    <th className="font-size-14px">Value for money</th>
                                                    <td className="text-end font-size-20px">
                                                      <RatingComponent ratingCount={profile?.reviews[0]?.valueMoneyRating} />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th className="font-size-14px">Equipment</th>
                                                    <td className="text-end font-size-20px">
                                                      <RatingComponent ratingCount={profile?.reviews[0]?.equipmentRating} />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th className="font-size-14px">All-through support</th>
                                                    <td className="text-end font-size-20px">
                                                      <RatingComponent ratingCount={profile?.reviews[0]?.facilitiesRating} />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th className="font-size-14px">Hygenic</th>
                                                    <td className="text-end font-size-20px">
                                                      <RatingComponent ratingCount={profile?.reviews[0]?.hygenicRating} />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th className="font-size-14px">Availability</th>
                                                    <td className="text-end font-size-20px">
                                                      <RatingComponent ratingCount={profile?.reviews[0]?.availabilityRating} />
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </div>
                                          </td>
                                        </tr>
                                      )}


                                      <tr>
                                        <th className="font-size-24px">Location</th>
                                        <td className="text-end font-size-20px">{profile.location}</td>
                                      </tr>
                                      <tr>
                                        <th className="font-size-24px">City</th>
                                        <td className="text-end font-size-20px">{profile.city}</td>
                                      </tr>
                                      <tr>
                                        <th className="font-size-24px">Specialisations</th>
                                        <td className="text-end font-size-20px">
                                          {profile?.specialties?.length > 0
                                            ? profile?.specialties?.map((item, index) => {
                                              return (
                                                <div key={index}>
                                                  <p>{item.name}</p>
                                                </div>
                                              );
                                            })
                                            : 'N/A'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="font-size-24px">Price</th>
                                        <td className="text-end font-size-20px">£ {profile.price}</td>
                                      </tr>
                                    </>
                                  }

                                  <tr>
                                    <th className="font-size-24px">Subscription</th>
                                    <td className="text-end font-size-20px">
                                      <div className='d-flex flex-column align-items-end justify-content-right'>
                                        {profile.userSubscription?.subscriptionId?.name}
                                        <button type="button" className="btn btn-outline-primary color-default font-bold mt-2" onClick={onNavigate}>
                                          Change Subscription
                                        </button>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th className="font-size-24px">User Type</th>
                                    <td className="text-end font-size-20px">
                                      <div className='d-flex flex-column align-items-end justify-content-right'>
                                        {usertype}
                                        <button type="button" className="btn btn-outline-primary color-default font-bold mt-2" onClick={onChangeUserType}>
                                          Change User Type
                                        </button>
                                      </div>
                                    </td>
                                  </tr>


                                  <tr>
                                    <th className="font-size-24px">Social media</th>
                                    <td className="text-end font-size-20px">
                                      <a href={profile?.facebookUrl} className="me-2">
                                        <img alt="facebook" src={fbIcon} />
                                      </a>
                                      <a href={profile?.instagramUrl} className="me-2">
                                        <img alt="Instagram" src={instaIcon} />
                                      </a>
                                      <a href={profile?.linkedinUrl} className="me-2">
                                        <img alt="LinkedIn" src={linkedinIcon} />
                                      </a>
                                      <a href={profile?.twitterUrl}>
                                        <img alt="Twitter" src={twitterIcon} />
                                      </a>
                                    </td>
                                  </tr>
                                  {
                                    profile.userSubscription?.subscriptionId?.isEnhancedProfile &&
                                    <>
                                      <tr>
                                        <th className="font-size-24px">Operating since</th>
                                        <td className="text-end font-size-20px">
                                          {profile.operatingSince}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="font-size-24px">Other related services</th>
                                        <td className="text-end font-size-20px">
                                          {profile.relatedServices.length > 0
                                            ? profile.relatedServices?.map((item, index) => {
                                              return (
                                                <div key={index}>
                                                  <p>{item}</p>
                                                </div>
                                              );
                                            })
                                            : 'N/A'}
                                        </td>
                                      </tr>
                                    </>
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {profile.clients.length > 0 && (
            <section className="featured pt-0" id="clientHighlight">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="title">
                      <h3 className="heading text-center">Client highlights</h3>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <Carousel
                      focusOnSelect={true}
                      itemsToShow={1}
                      pagination={false}
                      breakPoints={breakPoints}
                    >
                      {profile.clients?.map((client, index) => {
                        return (
                          <div key={index} className="card m-3 card-custom text-center">
                            <div className="card-icon">
                              <StyledImage src={endPoint.url + client.image || placeholder} alt={client.name} />
                            </div>
                            <div className="card-title">{client.name}</div>
                            <div className="card-content">{client.description}</div>
                          </div>
                        );
                      })}
                    </Carousel>

                  </div>
                </div>
              </div>
            </section>
          )}
          <UserTypeModal
            show={userTypeModelVisible}
            handleClose={() => setUserTypeModelVisible(false)}
            handleSubmit={onNavigateToSubscription}
          />
        </>
      )}
    </>
  );
};

export default Profile;
