import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Button from '../../atoms/Button/Index';
import InputField from '../../atoms/Input/Index';
import Spinner from '../../atoms/Spinner';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import toaster from '../../config/toaster';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetPasswordScreen = () => {

    let query = useQuery();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get("token")
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toaster("error", "Password does not match")
            return false;
        }
        const result = await axiosWrapper.updatePatch(`${endPoint.host}/passwordreset`, {
            userId,
            password
        })
        if (result.success) {
            toaster("success", result.message);
            setTimeout(() => {
                navigate("/login")
            }, 3000);
        }
    }

    useEffect(() => {
        if (!searchParams.get('token')) {
            navigate("/")
        }
    }, [])
    return (
        <>
            <ToastContainer />
            <section style={{ padding: "100px" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="title text-center">
                                <h3 className="heading">Reset Password</h3>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>New Password</label>
                                    <InputField
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        placeholder="New Password..."
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Confirm New Password</label>
                                    <InputField
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type="password"
                                        placeholder="Confirm Password..."
                                    />
                                </div>


                                <div className="text-center">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Button type="submit" variant="primary btn-pill fw-bold" text="Reset Password" />
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPasswordScreen;