import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../../atoms/Button/BackButton';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import ReviewScreen from './ReviewScreen';
import shieldIcon from '../../assets/img/icons/shield.svg';
import icInfo from '../../assets/img/ic-info.svg';
import icHeart from '../../assets/img/ic-heart.svg';
import icHeartFilled from '../../assets/img/heart-fill.svg';
import Rating from '../../atoms/Rating';
import toaster from '../../config/toaster';
import Spinner from '../../atoms/Spinner';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from '../../util';

const GymDetails = () => {
  const { id } = useParams();

  const [gymData, setGymData] = useState({});
  const [reviews, setReviews] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchGym = async () => {
    let result = await axiosWrapper.get(`${endPoint.host}/gym/${id}`, endPoint.header);
    if (result.success) {
      setGymData(result.data);
      const data = result?.data?.reviews;
      if (data.length) data.forEach(ele => ele.overallRating = Number(ele?.overallRating?.toFixed()))
      setReviews(data);
      setLoading(false);
    }
  };

  const onAddToFavorite = async () => {
    setLoading(true);
    const postData = {
      isFavorite: gymData.isFavorite ? false : true,
    }
    let result = await axiosWrapper.updatePatch(`${endPoint.host}/gym/${id}/favorite`, postData, endPoint.header);
    if (result.success) {
      toaster(`${postData.isFavorite ? 'success' : 'error'}`, `Event ${postData.isFavorite ? 'added to' : 'removed from'} favorite`);
      fetchGym();
    }
    else {
      setLoading(false);
      toaster('error', "Something went wrong");
    }
  }

  useEffect(() => {
    fetchGym();
  }, []);
  return (
    <div>
      <ToastContainer />
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex align-items-center justify-content-between mb-5">
              <BackButton />
            </div>
            <div className="col-11">
              <div className="title d-flex justify-content-between align-items-center">
                <div>
                  <img src={icInfo} className="img-fluid" alt="info icon" />
                </div>
                <div>
                  <h3 className="heading text-center">
                    <span>
                      <img src={shieldIcon} alt="shield icon" />
                    </span>
                    {gymData.name}
                  </h3>
                </div>
                <div>
                  {isLoading ? <Spinner /> :
                    <img
                      src={gymData.isFavorite ? icHeartFilled : icHeart}
                      className="img-fluid cursor-pointer"
                      style={{ width: '40px' }}
                      alt="favorite icon" onClick={onAddToFavorite}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="col-11 mb-100px">
              <div className="row justify-content-center serviceBoxes">
                <div className="col-lg-12">
                  <div className="card card-custom border-0 p-0">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-lg-5">
                          <img src={endPoint.url + gymData.image} width="400px" alt={gymData.name} />
                        </div>
                        <div className="col-lg-1" />
                        <div className="col-lg-6">
                          <p className="text-start" style={{ whiteSpace: 'pre-line' }}>{gymData.description}</p>
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <th className="font-size-24px">Rating</th>
                                <td className="text-end font-size-20px">
                                  <Rating rating={gymData.ratingAverage} />
                                  {`(${gymData.ratingCount})`}
                                </td>
                              </tr>
                              <tr>
                                <th className="font-size-24px">Location</th>
                                <td className="text-end font-size-20px">{gymData.location}</td>
                              </tr>
                              <tr>
                                <th className="font-size-24px">Specialisations</th>
                                <td className="text-end font-size-20px">
                                  {gymData.features?.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        {item}
                                        <br />
                                      </div>
                                    );
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <th className="font-size-24px">Price</th>
                                <td className="text-end font-size-20px">£{gymData.price}</td>
                              </tr>


                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ClientHighlights /> */}
      <ReviewScreen data={reviews} route={ROUTES.GYM} />
    </div>
  );
};

export default GymDetails;
