import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Button from '../../atoms/Button/Index';
import InputField from '../../atoms/Input/Index';
import Spinner from '../../atoms/Spinner';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import toaster from '../../config/toaster';

const ForgotScreen = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (email === '') {
            setLoading(false);
            toaster("error", "Email is required")
        }
        try {
            const result = await axiosWrapper.post(`${endPoint.host}/forgetpassword`, {
                email
            })
            if (result.success) {
                setLoading(false)
                setEmail("");
                toaster("success", result.message)
            }
        } catch (error) {
            setLoading(false)
            toaster("error", error.response.data.message)
        }
    }

    useEffect(() => {
        let token = localStorage.getItem("token")
        if (token) {
            navigate("/");
        }
    })
    return (
        <>
            <ToastContainer />
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="title text-center">
                                <h3 className="heading">Forgot Password</h3>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Email address</label>
                                    <InputField
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        placeholder="Email address..."
                                    />
                                </div>

                                <div className="text-center">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Button type="submit" variant="primary btn-pill fw-bold" text="Submit" />
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ForgotScreen;