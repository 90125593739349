import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Index';
import InputField from '../../atoms/Input/Index';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import Spinner from '../../atoms/Spinner/index';
import { ToastContainer } from 'react-toastify';
import toaster from '../../config/toaster';

const RegisterScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verificationIDSrc, setVerificationIDSrc] = useState("");
  const [verificationID, setVerificationID] = useState("");
  const [verificationIDName, setVerificationIDName] = useState("");
  const [isStudent, setIsStudent] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    mobile: '',
    password: '',
    company: '',
    repeat_password: '',
    type: '',
    consent: '',
  });

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleSubmitIndividual = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (values.password != values.repeat_password) {
      toaster('error', "Password did not match")
      setLoading(false);
      return false;
    }
    try {
      const form = new FormData();
      form.append('fullname', values.name);
      form.append('email', values.email);
      form.append('verificationID', verificationID);
      form.append('password', values.password);
      form.append('company', values.company);
      form.append('phone', values.mobile);
      form.append('usertype', "INDIVIDUAL");

      let result = await axiosWrapper.post(`${endPoint.host}/register`, form);
      if (result.success) {
        localStorage.setItem('token', result.data.newUser.token);
        setLoading(false);
        toaster('success', result.message)
        if (result.data.subscriptionSignup) {
          navigate('/subscription-individual');
        }
      } else {
        setLoading(false);
        toaster('error', result.message)
      }
    } catch (error) {
      setLoading(false);
      toaster('error', error.response.data.message)
    }
  };

  const handleSubmitCoach = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (values.password != values.repeat_password) {
      toaster('error', "Password did not match")
      setLoading(false);
      return false;
    }
    try {
      let result = await axiosWrapper.post(`${endPoint.host}/register`, {
        fullname: values.name,
        email: values.email,
        password: values.password,
        company: values.company,
        phone: values.mobile,
        usertype: 'COACH',
      });
      if (result.success) {
        localStorage.setItem('token', result.data.newUser.token);
        setLoading(false);
        toaster('success', result.message)
        if (result.data.subscriptionSignup) {
          navigate('/subscription-coach');
        }
      } else {
        setLoading(false);
        toaster('error', result.message)
      }
    } catch (error) {
      setLoading(false);
      toaster('error', error.response.data.message)
    }
  };

  const handleVerificationID = (e) => {
    setVerificationIDSrc(URL.createObjectURL(e.target.files[0]));
    setVerificationID(e.target.files[0]);
    setVerificationIDName('uploads/' + e.target.files[0].name);
  }
  return (
    <>
      <ToastContainer />

      <section className="register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="title text-center mb-40px">
                <p className="font-size-20px fw-bold">
                  Already have an account? <NavLink to="/login">Log in now!</NavLink>
                </p>
                <h3 className="heading my-40px">Register</h3>
                <p className="mb-0 font-size-24px text-center">I would like to register as...</p>
              </div>
              <div>
                <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Individual
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Coach
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <form onSubmit={handleSubmitIndividual}>
                          <div className="form-group">
                            <label>
                              Full name <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('name')}
                              type="text"
                              placeholder="Full Name"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Email address <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('email')}
                              type="email"
                              placeholder="Email Address"
                            />
                          </div>

                          <div className='form-group'>
                            <label>Are you a Student?</label>
                            <input
                              className="mx-3 form-check-input rounded-0"
                              type="checkbox"
                              defaultValue
                              onChange={(e) => setIsStudent(e.target.checked ? true : false)}
                              id="isStudent"
                            />
                          </div>
                          {isStudent && <div className="form-group">
                            <label className='px-1'>
                              Student Verification ID
                            </label>

                            <input
                              className="mx-3"
                              type="file"
                              required={isStudent ? "required" : ""}
                              onChange={handleVerificationID}
                              id="imageUpload" accept=".png, .jpg, .jpeg" />

                          </div>}

                          <div className="form-group">
                            <label>Mobile number</label>
                            <InputField
                              onChange={handleChange('mobile')}
                              type="tel"
                              placeholder="Mobile Number"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Password <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('password')}
                              type="password"
                              placeholder="Password"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Repeat password <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('repeat_password')}
                              type="password"
                              placeholder="Repeat Password"
                            />
                          </div>
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input rounded-0"
                                type="checkbox"
                                defaultValue
                                required
                                id="flexCheckDefault"
                              />
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                                I agree to{' '}
                                <NavLink
                                  className="text-decoration-underline fw-bold color-text-dark"
                                  to="/terms-and-conditions"
                                >
                                  terms and conditions
                                </NavLink>
                              </label>
                            </div>
                          </div>
                          <div className="text-center">
                            {loading ? (
                              <Spinner />
                            ) : (
                              <Button type="submit" text="Continue" variant="primary btn-pill" />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <form onSubmit={handleSubmitCoach}>
                          <div className="form-group">
                            <label>
                              Full name <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('name')}
                              type="text"
                              placeholder="Full Name"
                              name="fullName"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Company <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('company')}
                              type="text"
                              placeholder="Company"
                              name="companyName"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Email address <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('email')}
                              type="email"
                              placeholder="Email address"
                              name="email"
                            />
                          </div>

                          <div className="form-group">
                            <label>Mobile number</label>
                            <InputField
                              onChange={handleChange('mobile')}
                              type="tel"
                              placeholder="Mobile Number"
                              name="phone"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Password <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('password')}
                              type="password"
                              placeholder="Password"
                              name="password"
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Repeat password <span className="text-danger">*</span>
                            </label>
                            <InputField
                              onChange={handleChange('repeat_password')}
                              type="password"
                              placeholder="Repeat Password"
                              name="repeat_password"
                            />
                          </div>
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                onChange={handleChange('consent')}
                                className="form-check-input rounded-0"
                                type="checkbox"
                                defaultValue
                                required
                                id="flexCheckDefault"
                              />
                              <label className="form-check-label">
                                I agree to{' '}
                                <NavLink
                                  className="text-decoration-underline fw-bold color-text-dark"
                                  to="/terms-and-conditions"
                                >
                                  terms and conditions
                                </NavLink>
                              </label>
                            </div>
                          </div>
                          <div className="text-center">
                            {loading ? (
                              <Spinner />
                            ) : (
                              <Button type="submit" text="Continue" variant="primary btn-pill" />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterScreen;
