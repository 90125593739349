/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../atoms/Button/BackButton';
import Button from '../atoms/Button/Index';
import Cta from '../atoms/Cta';
import InputField from '../atoms/Input/Index';
import Spinner from '../atoms/Spinner';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import formatDate from '../config/formateDate';
import Pagination from '../Hooks/Pagination';
import { scrollToTop } from '../util';

const Separator = styled.hr`
  border: 1px solid #00a6fb;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
`;

let PageSize = 10;
const PER_PAGE = 1;

const EventsScreen = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [listEvents, setListEvents] = useState([]);
  const [creationAllowed, setCreationAllowed] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [haveFeatured, setHaveFeatured] = useState(false);


  const handleSubmit = async (e) => {
    e?.preventDefault();
    // setLoading(true);
    let result = await axiosWrapper.get(`${endPoint.host}/event?search=${searchText}`, endPoint.header);
    if (result.success) {
      if (result.data.events.length > 0) {
        setListEvents(result.data.events);
        setTotalRecords(result.data.total);
        setLoading(false);
      }
      else {
        setListEvents([]);
        setLoadingMessage("No events found");
        setTotalRecords(result.data.total);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (searchText === '' || searchText === undefined) {
      fetchEvents();
      const timer = setTimeout(() => {
        handleSubmit();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [searchText])

  const featuredExists = (data) => {
    // return data.some((item) => item.isFeatured === true);
    // count featured events using reduce
    return data.reduce((acc, item) => {
      if (item.isFeatured) {
        acc += 1;
      }
      return acc;
    }, 0);
  }

  const fetchEvents = async (skip = 0) => {
    let result = await axiosWrapper.get(`${endPoint.host}/event?skip=${skip}&search=${searchText}`, endPoint.header);
    if (result.success) {

      const countFeatured = featuredExists(result.data.events);
      setHaveFeatured(countFeatured > 0);

      setTotalRecords(result.data.total);
      setListEvents(result.data.events);
      setLoading(false);
      return result.data.events;
    }
  };

  useMemo(async () => {
    let data = await fetchEvents((currentPage - 1) * PER_PAGE);
    setListEvents(data);
    const countFeatured = featuredExists(data);
    setHaveFeatured(countFeatured > 0);
    scrollToTop();
    return data;
  }, [currentPage]);

  useEffect(() => {
    setCreationAllowed(localStorage.getItem('subscription') ? JSON.parse(localStorage.getItem('subscription')).eventsCreationAllowed : false)
    fetchEvents();
  }, []);
  return (
    <>
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-6">
              <div className="title d-flex flex-column justify-content-center align-items-center">
                <h3 className="heading text-center">Events</h3>
                <p className="text-center">
                  Explore upcoming events you can join
                </p>
                {creationAllowed &&
                  <Button type="button" variant="primary btn-pill" value="Add Event" onClick={() => navigate('/event/add')} text="Add Event" />
                }
              </div>
              <form onSubmit={handleSubmit}>
                <InputField type="text" name="search"
                  onChange={(e) => { setSearchText(e.target.value !== undefined ? e.target.value : '') }}
                  value={searchText} />
              </form>
            </div>

            <div className="col-lg-12">
              <div className="row justify-content-start serviceBoxes">
                {
                  haveFeatured && <>
                    <div className="text-center mb-40px">
                      <div className="font-size-32px fw-bold color-text-primary">Featured</div>
                    </div>

                    {listEvents.length > 0 ? listEvents?.map((item, index) => {
                      return (
                        <div className={`col-lg-6 col-6 ${item.isFeatured ? 'd-flex' : 'd-none'}`} key={index}>
                          <Cta
                            eventName={item.name}
                            icon={endPoint.url + item.image}
                            region={item.city}
                            date={formatDate(item.startTime, "MMMM Do YYYY, h:mm:ss a")}
                            btnText="See details"
                            link={`/event/${item._id}`}
                          />
                        </div>
                      );
                    })
                      :
                      <></>
                    }
                    <Separator />
                  </>
                }
                {loading ? <Spinner />
                  : listEvents.length > 0 ? listEvents?.map((item, index) => {
                    return (
                      <div className={`col-lg-6 col-6 ${!item.isFeatured ? 'd-flex' : 'd-none'}`} key={index}>
                        <Cta
                          eventName={item.name}
                          icon={endPoint.url + item.image}
                          region={item.city}
                          date={formatDate(item.startTime, "MMMM Do YYYY, h:mm:ss a")}
                          btnText="See details"
                          link={`/event/${item._id}`}
                        />
                      </div>
                    );
                  })
                    :
                    <div className="text-center">
                      <div className="font-size-32px fw-bold color-text-primary">{loadingMessage}</div>
                    </div>
                }
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalRecords}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventsScreen;
