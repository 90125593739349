import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Index';


const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  input {
    padding-right: 35px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: row;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    transition: 0.2s;
    cursor: pointer;
    color: #ccc;
    &:hover {
      color: #000;
    }
  }
`;

const InputField = (props) => {
  const { disabled, name, placeholder, isRequired, value, type, onChange, style } = props;
  return (
    name === 'search' ?
      <InputWrapper>
        <div className="input-group input-group-pill mb-40px" style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <span className="input-group-text bg-transparent" >
            <i className="fi fi-rr-search color-text-secondary d-flex" />
          </span>
          <Wrapper>
            <input
              disabled={disabled}
              onChange={onChange}
              required={isRequired}
              value={value !== "undefined" ? value : ""}
              type={type}
              name={name}
              className="form-control rounded-0"
              placeholder={placeholder}
            />
            {value && <i className='fi fi-rr-cross close-icon' onClick={onChange} />}
          </Wrapper>

          <Button type="submit" variant="primary" text="Search" />
        </div>
      </InputWrapper>
      : <input
        disabled={disabled}
        onChange={onChange}
        required={isRequired}
        value={value !== "undefined" ? value : ""}
        type={type}
        name={name}
        className="form-control rounded-0"
        placeholder={placeholder}
        style={style}
      />
  );
};

InputField.defaultProps = {
  isRequired: false,
}

export default InputField;
