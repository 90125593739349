import axios from "axios";
import React, { useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import BackButton from "../../atoms/Button/BackButton";
import Button from "../../atoms/Button/Index";
import InputField from "../../atoms/Input/Index";
import Spinner from "../../atoms/Spinner";
import endPoint from "../../config/endPoints";
import toaster from "../../config/toaster";

const CheckInput = styled.input`
  width: 25px;
  height: 25px;
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;

const AddGym = () => {

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [gymPic, setGymPic] = useState('');
  const [gymData, setGymData] = useState({});
  const [location, setLocation] = useState("");
  const [gymFeatures, setGymFeatures] = useState([""]);

  const handleImageChange = (e) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]));
    setGymPic(e.target.files[0]);
    setGymData({
      ...gymData,
      image: e.target.files[0]
    });
  }

  const onAddFeature = () => {
    setGymFeatures([...gymFeatures, '']);
  }

  const onRemoveFeature = (index) => {
    gymFeatures.splice(index, 1);
    setGymFeatures([...gymFeatures]);
  }

  const onChangeFeature = (event, index) => {
    const { value } = event.target;
    gymFeatures[index] = value;
    setGymFeatures([...gymFeatures]);
  }

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    setGymData({ ...gymData, [name]: value });
  }

  const onChangeLocation = (place) => {
    console.log('place', place);
    setLocation(place.formatted_address);
    setGymData({
      ...gymData,
      city: place.address_components[0].long_name,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (gymPic === '' || gymPic === undefined) {
      toaster('error', 'Please select gym picture');
      window.scrollTo(0, 0);
      return;
    }

    const form = new FormData();
    form.append('image', gymPic);
    form.append('name', gymData.name);
    form.append('description', gymData.description);
    form.append('address', location);
    form.append('city', gymData.city);
    form.append('price', gymData.price);
    form.append('features', JSON.stringify(gymFeatures));

    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };

    let result = await axios.post(`${endPoint.host}/gym`, form, config);
    if (result.data.success) {
      toaster('success', result.data.message);
      setLoading(false);
      setTimeout(() => {
        navigate('/gymelite');
      }, 1000);
    }
    else {
      setLoading(false);
      toaster('error', 'Something went wrong');
    }
  }

  return (
    <>
      {isLoading ? <div style={{ padding: "100px" }}>
        <Spinner />
      </div> :
        <>
          <ToastContainer />
          <section className='pt-40px'>
            <div className="container">
              <div className="row justify-content-center">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <BackButton />
                </div>

                <div className="col-lg-5">
                  <form onSubmit={handleSubmit} className="addGym">
                    <div className="container">
                      <h4>Gym Picture
                      </h4>
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input type="file" onChange={handleImageChange} id="imageUpload" accept=".png, .jpg, .jpeg" />
                          <label htmlFor="imageUpload" />
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview" style={{ backgroundImage: imgSrc ? `url(${imgSrc})` : `url(${endPoint.url + gymData?.image})` }}>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Name</label>
                      <InputField
                        onChange={onChangeInput}
                        name="name"
                        value={gymData?.name}
                        type="text"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        onChange={onChangeInput}
                        name="description"
                        value={gymData?.description}
                        type="text"
                        className="form-control"
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <label>Address</label>
                      </div>
                      <ReactGoogleAutocomplete
                        placeholder="Address"
                        className='form-control'
                        name="address"
                        defaultValue={location?.address}
                        apiKey={endPoint.locationApiKey}
                        onPlaceSelected={(place) => {
                          onChangeLocation(place)
                        }}
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>City</label>
                      <InputField
                        onChange={onChangeInput}
                        name="city"
                        value={gymData?.city}
                        type="text"
                        isRequired={true}
                        disabled={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Price</label>
                      <InputField
                        onChange={onChangeInput}
                        name="price"
                        value={gymData?.price}
                        type="number"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Features</label>
                      <div className="d-flex flex-column justify-content-between align-items-center gap-2">
                        {gymFeatures.length > 0 ? gymFeatures.map((feature, index) => (
                          <FeatureWrapper>
                            <InputField
                              style={{ marginTop: 0 }}
                              onChange={(e) => onChangeFeature(e, index)}
                              name="feature"
                              value={feature}
                              type="text"
                              isRequired={true}
                            />
                            <i className="fi fi-rr-add text-lg cursor-pointer" onClick={onAddFeature} />
                            {gymFeatures.length > 1 && <i className="fi fi-rr-trash text-danger text-lg cursor-pointer" onClick={() => onRemoveFeature(index)} />}
                          </FeatureWrapper>
                        ))
                          : <div className="d-flex flex-column justify-content-start align-items-center">
                            <label>Add Feature</label>
                            <i className="fi fi-rr-plus text-lg cursor-pointer" onClick={onAddFeature} />
                          </div>
                        }
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Button text="Add Gym" type="submit" variant="primary btn-pill" />
                    </div>

                  </form>
                </div>

              </div>
            </div>
          </section>
        </>
      }
    </>
  );
}

export default AddGym;
