import React, { useEffect, useState } from 'react';
import Image from '../atoms/Image/Index';
import heroImage from '../assets/img/hero-img.jpg';
import Button from '../atoms/Button/Index';
import Blurb from '../molecules/Blurb/Index';
import Card from '../molecules/Card';
import NetworkIcon from '../assets/img/ic-network.png';
import SaveUpIcon from '../assets/img/ic-saveup.png';
import ExploreIcon from '../assets/img/ic-explore.png';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import Spinner from '../atoms/Spinner';
import Carousel from 'react-elastic-carousel';
import { trimText } from '../util';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DiscountModal from '../popups/DiscountModal';

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const HomeScreen = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
  ];

  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [featuredGyms, setFeaturedGyms] = useState([]);
  const [featuredTrainers, setFeaturedTrainer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discountAllowed, setDiscountAllowed] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [discountData, setDiscountData] = useState({});


  const fetchCategories = async () => {
    let data = await axiosWrapper.get(`${endPoint.host}/categories`);
    if (data.success) {
      setCategories(data.data);
      setLoading(!loading);
    }
  };

  const fetchTrainers = async () => {
    setLoading(true);
    let result = await axiosWrapper.get(`${endPoint.host}/trainers?isFeatured=true`, endPoint.header);
    if (result.success) {
      let data = result?.data?.trainers;
      setFeaturedTrainer(data);
      setLoading(false);
    }
  }


  const fetchGyms = async () => {
    setLoading(true);
    let result = await axiosWrapper.get(`${endPoint.host}/gym`, endPoint.header);
    if (result.success) {
      let data = result?.data?.filter((item) => item.isFeatured)
      setFeaturedGyms(data);
      setLoading(false);
    }
  }

  const fetchDiscountListing = async () => {
    const response = await fetch(`${endPoint.host}/discount`);
    const prasedData = await response.json();
    if (prasedData && prasedData.data.total > 0) {
      setDiscounts(prasedData.data.discounts);
    }
    else {
      setDiscounts([]);
    }
  }

  const fetchDiscounts = async () => {
    const isLoggedIn = localStorage.getItem('token') ? true : false;
    const discounts = localStorage.getItem('subscription') ? JSON.parse(localStorage.getItem('subscription')) : null;
    if (isLoggedIn) {
      if (discounts && discounts.discountsAllowed) {
        setDiscountAllowed(true);
      }
    }
    else {
      setDiscountAllowed(false);
    }
  }


  let token = localStorage.getItem("token");
  useEffect(() => {
    fetchCategories();
    fetchDiscounts();
    fetchTrainers();
    fetchDiscountListing();

    const isLoggedIn = localStorage.getItem('token') ? true : false;
    setLoggedIn(isLoggedIn);
  }, []);

  let pointCards = [
    {
      title: 'Network',
      description: 'Make informed network choices from our coach reviews and ratings.',
      icon: NetworkIcon,
    },
    {
      title: 'Discounts',
      description:
        'Save up to 20% on your gym memberships and coaches!',
      icon: SaveUpIcon,
    },
    {
      title: 'Explore',
      description: 'Never miss a crucial event again! Browse all fitness events in your field.',
      icon: ExploreIcon,
    },
  ];

  const onViewDiscount = (item) => {
    setDiscountData(item);
    setShowDiscountModal(true);
  }


  return (
    <>
      <section className="hero color-dark p-0">
        <div className="row g-0">
          <div className="col-lg-6">
            <Image altText="hero image" className="img-fluid" path={heroImage} />
          </div>
          <div className="col-lg-6">
            <div className="row h-100 align-items-center g-0 justify-content-center">
              <div className="col-lg-10 p-3-sm">
                <div className='row'>
                  <div className='col-lg-10'>
                    <h1 className="hero-title text-white">Be Elite.<br></br> Be Pro-AthElite.</h1>
                    <h2 className="hero-sub-title text-white">Are you a coach open to new clients?</h2>
                    <h4 className="text-white"> Are you seeking
                      to <span>level up your training programme,</span> and <span>connect with experts in your discipline</span>?
                    </h4>
                    <p className="hero-content text-white">Pro-AthElite is a network that brings together
                      coaches, athletes and suppliers.</p>

                    {!token &&
                      <Button link="/register" variant="primary btn-pill font-size-30px btn-lg hero-btn" text="Join now" />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* cards section */}
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="title">
                <h3 className="heading text-center font-size-50px font-weight-700">Find your Discipline</h3>
              </div>
            </div>
            {loading && <Spinner />}
            <div className="col-10">
              <div className="row justify-content-center serviceBoxes">
                {categories?.map((item, index) => {
                  return (
                    <div className="col-lg-6" key={index}>
                      <Blurb
                        link={item.name.toLowerCase()}
                        bgImage={endPoint.url + item.image}
                        title={item.name}
                        description={item.description}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why pro athelete section */}
      <section className="color-dark whyProAthelite">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title">
                <h3 className="heading text-center text-white font-size-40px">Why Pro-Athelite?</h3>
              </div>
            </div>
            <div className="col-lg-11">
              <div className="row justify-content-center serviceBoxes">
                {pointCards?.map((item, index) => {
                  return (
                    <div className="col-lg-4 mb-1" key={index}>
                      <Card icon={item.icon} title={item.title} description={item.description} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="featured">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title" style={{ marginBottom: "30px" }}>
                <h3 className="heading text-center">Featured</h3>
              </div>
            </div>
            {/* {token ? <> */}
            <>
              {featuredTrainers.length > 0 ?
                <div className="col-lg-12">
                  <Carousel
                    focusOnSelect={true}
                    itemsToShow={1}
                    pagination={false}
                    breakPoints={breakPoints}
                  >
                    {featuredTrainers?.map((item, index) => {
                      return (
                        <div key={index}
                          onClick={() => { if (isLoggedIn) navigate(`/trainer_profile/${item._id}`) }}
                          className="card card-custom text-center"
                          style={{ width: "95%", filter: `${isLoggedIn ? '' : 'blur(3px)'}`, cursor: `${isLoggedIn ? 'pointer' : ''}` }}>
                          <div className="card-icon">
                            <StyledImage src={endPoint.url + item.profilePic} alt={item.profilePic} />
                          </div>
                          <div className="card-title">{item.fullname}</div>
                          <div className="card-content">
                            <span className="label label-inline color-primary text-white">Category</span>
                            <span className="label label-inline color-primary text-white">Category</span>
                            <span className="label label-inline color-primary text-white">Category</span>
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
                :
                <div className='col-lg-12'>
                  <div className='alert alert-primary text-center'>
                    No featured Trainers available
                  </div>
                </div>
              }
            </>
            {/* :
              <div className='alert alert-primary text-center'>
                <Link to="/register">Join now to see all the featured Gyms</Link>
              </div>
            } */}
          </div>
        </div>
      </section >

      <section className="discount color-dark" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title text-center">
                <h3 className="heading text-center text-white font-size-60px">Discounts</h3>
              </div>
            </div>

            <div className='col-12'>
              <Carousel
                itemPadding={[0, 10]}
                pagination={false}
                breakPoints={breakPoints}
              >
                {discounts.filter(d => d.isActive).length > 0 ? discounts.filter(d => d.isActive).map((item, index) => {
                  return (
                    <div key={index} className="card card-custom text-center" style={{ minHeight: "520px", filter: discountAllowed ? '' : 'blur(3px)' }}>
                      <div className="card-icon">
                        <img src={`${endPoint.url}/${item.image}`} alt='discount icon' style={{ maxWidth: '100%', height: '200px' }} />
                      </div>
                      <div className="card-title" style={{ minHeight: "130px" }}>{trimText(item.description, 55)}</div>
                      <div className="card-content">
                        <Button text="Get discount" 
                        // link={token ? '/' : '/register'} 
                        variant="primary btn-pill" onClick={() => { if(discountAllowed) onViewDiscount(item); }} />
                      </div>
                    </div>
                  )
                })
                  :
                  <div className='alert alert-primary text-center'>
                    No discounts available
                  </div>
                }
              </Carousel>
            </div>


          </div>
        </div>
        <DiscountModal
          data={discountData}
          show={showDiscountModal}
          handleClose={() => setShowDiscountModal(false)}
        />
      </section >
    </>
  );
};

export default HomeScreen;
