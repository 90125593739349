import React from 'react';
import { NavLink } from 'react-router-dom';

const Card = (props) => {
  const { icon, title, description } = props;
  return (
    <>
      <div className="card card-custom card-stretch text-center">
        <div className="card-body">
          <div className="card-icon">
            <img src={icon} alt="Explore" className="img-fluid" />
          </div>
          <div className="card-title">{title}</div>
          <div className="card-content">{description}</div>
        </div>
      </div>
    </>
  );
};

export default Card;
