import React from 'react';

const Snackbar = (props) => {
  const { message, onClose, type } = props;

  const handleClose = () => {
    onClose(false);
  }


  return (
    <>
      <div className="position-fixed bottom-0 end-0 p-3">
        <div
          className={`'toast' ${type === 'alert' ? 'bg-danger' : 'bg-success'}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >

          <div className="toast-header">
            <strong className="me-auto">{type.toUpperCase()}</strong>
            <button
              onClick={handleClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            />
          </div>
          <div className="toast-body text-white">{message}</div>
        </div>
      </div>
    </>
  );
};

export default Snackbar;
