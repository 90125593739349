import React, { useEffect, useState } from 'react';
import BackButton from '../../atoms/Button/BackButton';
import InputField from '../../atoms/Input/Index';
import fbIcon from '../../assets/img/icons/fb.svg';
import instaIcon from '../../assets/img/icons/insta.svg';
import linkedinIcon from '../../assets/img/icons/linkedin.svg';
import twitterIcon from '../../assets/img/icons/twitter.svg';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import Button from '../../atoms/Button/Index';
import Spinner from '../../atoms/Spinner/index';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import toaster from '../../config/toaster';
import { TagsInput } from "react-tag-input-component";
import { Multiselect } from "multiselect-react-dropdown";
import Autocomplete from "react-google-autocomplete";
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const EditProfile = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [imgSrc, setImgSrc] = useState("");
  const [imgHighlightSrc, setImgHighlightSrc] = useState([]);
  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('-');
  const [location, setLocation] = useState(null);
  const [city, setCity] = useState(null);
  const [isOnline, setOnline] = useState(false);
  const [price, setPrice] = useState('');
  const [specialization, setSpecialization] = useState([]);
  const [listSpecialization, setListSpecialization] = useState([]);
  const [operatingSince, setOperatingSince] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('#');
  const [instagramUrl, setInstagramUrl] = useState('#');
  const [linkedinUrl, setLinkedinUrl] = useState('#');
  const [twitterUrl, setTwitterUrl] = useState('#');
  const [enableEditing, setEnableEditing] = useState(0);
  const [relatedServices, setRelatedServices] = useState([]);
  const [images, setImages] = useState([]);
  const [fields, setFields] = useState([]);

  const [clientName, setClientName] = useState('');
  const [clientDesc, setClientDesc] = useState('');
  const [clientImage, setClientImage] = useState('');

  const [updatedClientName, setUpdatedClientName] = useState('');
  const [updatedClientDesc, setUpdatedClientDesc] = useState('');
  const [updatedClientImage, setUpdatedClientImage] = useState('');

  const [deletedClients, setDeletedClients] = useState([]);
  const [updatedClients, setUpdatedClients] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [allClients, setAllClients] = useState([]);

  const [profilePic, setProfilePic] = useState('');
  const [profilePicName, setProfilePicName] = useState('');
  const [preview, setPreview] = useState([]);

  const clientObj = {
    name: "",
    description: "",
    image: "",
    isNew: true,
  }

  useEffect(() => {
    console.log('newClients===>', newClients, images);
  }, [newClients])

  const handleDelete = (id) => {
    let data = allClients.filter(client => client._id !== id);
    setAllClients(data);
    setDeletedClients([...deletedClients, { id }])
  }

  const handleSave = (item, index) => {
    // const currentClient = myNewClients;
    // currentClient[index].name = updatedClientName;
    // currentClient[index].description = updatedClientDesc;
    // currentClient[index].image = updatedClientImage;
    // setMyNewClients([...currentClient]);
    // return;
    console.log('item', item);
    setUpdatedClients([...updatedClients, {
      clientId: item._id,
      name: updatedClientName || item.name,
      description: updatedClientDesc || item.description,
      image: updatedClientImage.name ? 'uploads/' + updatedClientImage.name : item.image
    }])
    setEnableEditing(0)
  }

  const fetchSpecialization = async () => {
    let result = await axiosWrapper.get(`${endPoint.host}/subcategories`);
    if (result.success) {
      setListSpecialization(result.data);
    }
  }

  const onSelect = (selectedList, selectedItem) => {
    setSpecialization(selectedList);
  }


  const onRemove = (selectedList, removedItem) => {
    setSpecialization(selectedList);
  }

  const handleChangeImage = (e, client, index) => {
    setUpdatedClientImage(e.target.files[0]);
    setImages([...images, e.target.files[0]]);
    const { name } = e.target;
    initializePreviews(name, index, e.target.files[0])
  }

  const initializePreviews = (name, index, file) => {
    console.log('file====>', file);
    const obj = {
      name: name,
      file: URL.createObjectURL(file),
      fileData: file
    }
    const data = preview;
    data[index] = obj;
    setPreview(
      [...data]
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      fullname,
      email,
      phone,
      profilePicName,
      description,
      company,
      facebookUrl,
      location,
      city,
      operatingSince,
      price,
      instagramUrl,
      linkedinUrl,
      twitterUrl,
      newClients,
      deletedClients,
      updatedClients,
      specialties: specialization && specialization.length > 0 ? specialization.map(item => item._id) : [],
      relatedServices
    }

    const form = new FormData();
    imgHighlightSrc.forEach((img) => {
      form.append('images', img.fileData);
    })
    form.append("profilePic", profilePic || '');
    form.append("data", JSON.stringify(data));

    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };

    console.log('images', images);
    for (var key of form.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }
    // return;
    let result = await axios.post(`${endPoint.host}/editUserProfile`, form, config);

    if (result.data.success) {
      setLoading(false);
      toaster('success', result.data.message)
      setTimeout(() => {
        navigate("/profile")
        window.scrollTo(0, 0);
      }, 2000);
    } else {
      setLoading(false);
      toaster('error', result.message)
    }

  };

  const fetchProfile = async () => {
    setLoading(false);
    let result = await axiosWrapper.get(`${endPoint.host}/me`, endPoint.header);
    console.log("Re", result);
    if (result.success) {
      setProfile(result.data);
      setAllClients(result.data.clients);

      result.data.clients.length > 0 && result.data.clients.map((client, index) => {
        //   initializePreviews(client.name, index, client.image)
        setPreview(prevState => [...prevState,
        {
          name: client.name,
          file: endPoint.url + client.image,
          fileData: endPoint.url + client.image,
        }]);
      })

      setFullName(result.data.fullname);
      setEmail(result.data.email);
      setPhone(result.data.phone);
      setCompany(result.data.company);
      setDescription(result.data.description);
      setLocation(result.data.location);
      setCity(result.data.city);
      setOnline(result.data.location === "Online" ? true : false);
      setPrice(result.data.price);
      setPrice(result.data.price);
      setSpecialization(result.data.specialties);
      setOperatingSince(result.data.operatingSince);
      setFacebookUrl(result.data.facebookUrl);
      setInstagramUrl(result.data.instagramUrl);
      setLinkedinUrl(result.data.linkedinUrl);
      setTwitterUrl(result.data.twitterUrl);
      setRelatedServices(result.data.relatedServices);
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]));
    setProfilePic(e.target.files[0]);
    setProfilePicName('uploads/' + e.target.files[0].name);
  }

  const usertype = localStorage.getItem("usertype")
  useEffect(() => {
    fetchProfile();
    fetchSpecialization();
  }, []);

  const handleHighlightImageChange = (event, index) => {
    const { name } = event.target;
    console.log('event', event.target.files[0]);
    const obj = {
      name: name,
      file: URL.createObjectURL(event.target.files[0]),
      fileData: event.target.files[0]
    }
    const data = imgHighlightSrc;
    data[index] = obj;
    setImgHighlightSrc(
      [...data]
    );
  }

  useEffect(() => {
    console.log('imgHighlightSrc', imgHighlightSrc);
  }, [imgHighlightSrc])
  return (
    <>

      {loading ? <div style={{ padding: "100px" }}>
        <Spinner />
      </div> :
        <>
          <ToastContainer />
          <section className='pt-40px'>
            <div className="container">
              <div className="row justify-content-center">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <BackButton />
                </div>
                <div className="col-lg-5">
                  <form onSubmit={handleSubmit} className="trainerProfile">

                    <div className="container">
                      <h4>Profile Picture
                      </h4>
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input type="file" onChange={handleImageChange} id="imageUpload" accept=".png, .jpg, .jpeg" />
                          <label htmlFor="imageUpload" />
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview" style={{ backgroundImage: imgSrc ? `url(${imgSrc})` : `url(${endPoint.url + profile.profilePic})` }}>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Name</label>
                      <InputField
                        onChange={(e) => setFullName(e.target.value)}
                        value={fullname}
                        type="text"
                      />
                    </div>
                    {profile.userSubscription?.subscriptionId?.isEnhancedProfile &&
                      <>
                        <div className="form-group">
                          <label>Company name</label>
                          <InputField
                            onChange={(e) => setCompany(e.target.value)}
                            value={company}
                            type="text"
                          />
                        </div>

                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control"
                            value={description}
                          />
                        </div>
                      </>
                    }
                    {usertype === 'INDIVIDUAL' ?
                      <>
                        <div className="form-group">
                          <label>Email Address</label>
                          <InputField
                            disabled={true}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                          />
                        </div>

                        <div className="form-group">
                          <label>Phone</label>
                          <InputField
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            type="text"
                          />
                        </div>
                      </>
                      :
                      <>
                        <div className="form-group">
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <label>Location</label>
                            <div className="form-group m-0">
                              <div className="form-check d-flex flex-row align-items-center">
                                <input
                                  className="mx-2 form-check-input rounded-0"
                                  type="checkbox"
                                  defaultValue
                                  checked={isOnline}
                                  onChange={(e) => {
                                    setOnline(e.target.checked ? true : false);
                                    setLocation(e.target.checked ? 'Online' : "");
                                    setCity(e.target.checked ? 'Online' : "");
                                  }}
                                  id="isOnline"
                                />
                                <label className="form-check-label m-0 text-lg" htmlFor="isOnline">Online</label>
                              </div>
                            </div>
                          </div>

                          <Autocomplete
                            placeholder="Enter location"
                            disabled={isOnline}
                            className='form-control'
                            defaultValue={isOnline ? 'Online' : location}
                            // defaultValue={location}
                            apiKey={endPoint.locationApiKey}
                            onPlaceSelected={(place) => {
                              setLocation(place.formatted_address)
                              setCity(place.address_components[0].long_name)
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <InputField
                            onChange={(e) => setCity(e.target.value)}
                            // defaultValue={isOnline ? 'Online' : city}
                            value={city}
                            type="text"
                            disabled={isOnline}
                          />
                        </div>
                        <div className="form-group">
                          <label>Price</label>
                          <InputField
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label>Specialisations</label>
                          <Multiselect
                            options={listSpecialization}
                            selectedValues={profile.specialties}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="name"
                          />
                        </div></>
                    }
                    {profile.userSubscription?.subscriptionId?.isEnhancedProfile &&
                      <div className="form-group">
                        <label>Operating since</label>
                        <InputField
                          onChange={(e) => setOperatingSince(e.target.value)}
                          value={operatingSince}
                          type="text"
                        />
                      </div>
                    }
                    <div className="form-group">
                      <label>Social</label>
                      <div className="input-group input-group-pill mb-40px w-full">
                        <span className="input-group-text bg-transparent border-end-0">
                          <img src={fbIcon} alt="Facebook" />
                        </span>
                        <InputField
                          isRequired={false}
                          placeholder="Paste URL..."
                          onChange={(e) => setFacebookUrl(e.target.value)}
                          value={facebookUrl}
                          type="text"
                        />
                      </div>
                      <div className="input-group input-group-pill mb-40px">
                        <span className="input-group-text bg-transparent border-end-0">
                          <img src={instaIcon} alt="instagram" />
                        </span>
                        <InputField
                          isRequired={false}
                          placeholder="Paste URL..."
                          onChange={(e) => setInstagramUrl(e.target.value)}
                          value={instagramUrl}
                          type="text"
                        />
                      </div>
                      <div className="input-group input-group-pill mb-40px">
                        <span className="input-group-text bg-transparent border-end-0">
                          <img src={linkedinIcon} alt="LinkedIn" />
                        </span>
                        <InputField
                          isRequired={false}
                          placeholder="Paste URL..."
                          onChange={(e) => setLinkedinUrl(e.target.value)}
                          value={linkedinUrl}
                          type="text"
                        />
                      </div>
                      <div className="input-group input-group-pill mb-40px">
                        <span className="input-group-text bg-transparent border-end-0">
                          <img src={twitterIcon} alt="Twitter" />
                        </span>
                        <InputField
                          isRequired={false}
                          placeholder="Paste URL..."
                          onChange={(e) => setTwitterUrl(e.target.value)}
                          value={twitterUrl}
                          type="text"
                        />
                      </div>
                    </div>
                    {profile.userSubscription?.subscriptionId?.isEnhancedProfile &&
                      <div className="form-group">
                        <label>Other related services</label>
                        <TagsInput
                          isRequired={false}
                          value={relatedServices.length > 0 ? relatedServices : []}
                          onChange={setRelatedServices}
                          name="relatedServices"
                          placeHolder="e.g motivational"
                        />

                      </div>
                    }
                    {profile.usertype === 'COACH' &&
                      <>
                        <div className="form-group">
                          <label>Client highlights</label>
                        </div>

                        {/* {myNewClients?.map((item, index) => { */}
                        {allClients?.map((client, index) => {
                          return (
                            <div key={index} className="form-group">
                              <h5>Client #{index + 1}</h5>

                              <div className="mb-5">
                                <StyledImage alt="Highlight"
                                  // src={(endPoint.url + client?.image) || preview[index].file} />
                                  src={preview[index].file} />
                                <div>
                                  {enableEditing === client?._id ?
                                    <button type='button' onClick={() => handleSave(client, index)} className='btn btn-sm btn-success'>Save</button> :
                                    <button
                                      type="button"
                                      onClick={() => handleDelete(client?._id)}
                                      className="btn btn-sm btn-danger m-1"
                                    >
                                      Delete
                                    </button>
                                  }
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (enableEditing !== client?._id) {
                                        setEnableEditing(client?._id)
                                      }
                                    }
                                    }
                                    className="btn btn-sm btn-primary m-1"
                                  >
                                    Edit
                                  </button>
                                </div>
                                <div className={enableEditing === client?._id ? 'editing' : ''}>
                                  {enableEditing === client?._id &&
                                    <div>
                                      <input
                                        onChange={(event) => {
                                          handleChangeImage(event, client, index)
                                        }}
                                        name={'client' + Number(index + 1)}
                                        type="file"
                                        id="uploadFile"
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </div>
                                  }


                                  <input
                                    disabled={enableEditing !== client?._id ? true : false}
                                    onChange={(e) => {
                                      setUpdatedClientName(e.target.value);

                                    }}
                                    type="text"
                                    className="form-control"
                                    defaultValue={client.name}
                                  />
                                  <textarea
                                    disabled={enableEditing !== client?._id ? true : false}
                                    rows={4}
                                    defaultValue={client.description}
                                    onChange={(e) => {
                                      setUpdatedClientDesc(e.target.value);

                                    }}
                                    className="form-control mt-2"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {fields.map((item, index) => {
                          return (
                            <div key={index} className="mb-5">
                              <div className="mb-3 uploadFileWrapper1">
                                <label htmlFor="uploadFile pb-3">
                                  <strong>Highlight Photo</strong>
                                </label>
                                <input
                                  name={'client-' + Number(index + 1)}
                                  onChange={(event) => {
                                    setClientImage(event.target.files[0]);
                                    setImages([...images, event.target.files[0]]);
                                    // setImgHighlightSrc([...imgHighlightSrc, URL.createObjectURL(event.target.files[0])]);
                                    handleHighlightImageChange(event, index);
                                  }}
                                  className="form-control"
                                  type="file"
                                  id="uploadFile"
                                />
                                {imgHighlightSrc[index] && <StyledImage style={{ backgroundImage: `url(${imgHighlightSrc[index].file})` }} />}
                              </div>
                              <input
                                onChange={(e) => setClientName(e.target.value)}
                                type="text"
                                className="form-control"
                                defaultValue=""
                                placeholder="Highlight Name"
                              />
                              <textarea
                                placeholder="Highlight Description"
                                rows={4}
                                onChange={(e) => setClientDesc(e.target.value)}
                                className="form-control mt-2"
                                defaultValue={''}
                              />
                              <button type="button" onClick={() => {
                                setNewClients([
                                  ...newClients,
                                  {
                                    name: clientName,
                                    description: clientDesc,
                                    image: `uploads/${clientImage.name}`,
                                  },
                                ]);
                                toaster("success", "Image added to list.");
                              }} className='btn btn-primary btn-sm mt-3'>Save</button>
                              {/* delete button */}
                              <button
                                type="button"
                                onClick={() => {
                                  const values = [...fields];
                                  values.splice(index, 1);
                                  setFields(values);
                                }}
                                className="btn btn-sm btn-danger mt-3 ms-3"
                              >
                                Delete
                              </button>
                            </div>
                          );
                        })}

                        <Button
                          onClick={() => {
                            setFields([...fields, 1]);
                            // setMyNewClients([...myNewClients, clientObj]);
                          }}
                          variant="r btn-dark text-start d-flex align-items-center gap-10px py-3 w-100 mt-2"
                          text="Add new client
                  highlight"
                          type="button"
                          icon="fi fi-rr-plus-small d-flex align-items-center"
                        />
                      </>
                    }

                    <div className="text-center mt-5">
                      <button type="submit" className="btn px-5 btn-pill color-primary text-white">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section >
        </>
      }

    </>
  );
};

export default EditProfile;
