import React from 'react';

const Image = (props) => {
  const { path, altText, className } = props;
  return (
    <>
      <img src={path} className={className} alt={altText} />
    </>
  );
};

export default Image;
