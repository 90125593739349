import React, { useEffect, useState } from 'react';
import Button from '../atoms/Button/Index';
import Cta from '../atoms/Cta';
import InputField from '../atoms/Input/Index';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import BackButton from '../atoms/Button/BackButton';
import Spinner from '../atoms/Spinner';
import GymSearchFilter from '../organism/SearchFilter/GymSearchFilter';
import styled from 'styled-components';
import Pagination from '../Hooks/Pagination';
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../util';

let PageSize = 10;
const PER_PAGE = 1;

const GymEliteScreen = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [listGyms, setListGyms] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [haveFeatured, setHaveFeatured] = useState(false);
  const [creationAllowed, setCreationAllowed] = useState(false);

  const Separator = styled.hr`
    border: 1px solid #00a6fb;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
`;

  const handleSubmit = async (e) => {
    e?.preventDefault();
    let result = await axiosWrapper.get(`${endPoint.host}/gym?search=${searchText}&limit=10`, endPoint.header);
    if (result.success) {
      if (result.data.gyms.length > 0) {
        setListGyms(result.data.gyms);
        setTotalRecords(result.data.total);
        setHaveFeatured(featuredExists(result.data.gyms));
        setLoading(false);
      }
      else {
        setListGyms([]);
        setLoadingMessage("No gyms found");
        setTotalRecords(result.data.total);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (searchText === '' || searchText === undefined) {
      fetchGyms();
      const timer = setTimeout(() => {
        handleSubmit();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [searchText])

  useEffect(() => {
    setCreationAllowed(localStorage.getItem('subscription') ? JSON.parse(localStorage.getItem('subscription')).gymCreationAllowed : false)
  }, []);

  const featuredExists = (data) => {
    return data.some((item) => item.isFeatured === true);
  }

  const fetchGyms = async (skip = 0) => {
    let result = await axiosWrapper.get(`${endPoint.host}/gym?skip=${skip}&search=${searchText}`, endPoint.header);
    if (result.success) {
      if (result.data.gyms.length > 0) {
        const countFeatured = featuredExists(result.data.gyms)
        setHaveFeatured(countFeatured);
  
        setTotalRecords(result.data.total);
        setListGyms(result.data.gyms);
        setLoading(false);
        return result.data.gyms;
      }
      else {
        setListGyms([]);
        setLoadingMessage("No gyms found");
        setTotalRecords(result.data.total);
        setLoading(false);
        return [];
      }
    }
  };

  React.useMemo(async () => {
    let data = await fetchGyms((currentPage - 1) * PER_PAGE);
    setListGyms(data);
    scrollToTop();
    return data;
  }, [currentPage]);

  // ==============================================================

  // const fetchGyms = async () => {
  //   let result = await axiosWrapper.get(`${endPoint.host}/gym`, endPoint.header);
  //   if (result.success) {
  //     setListGyms(result.data.gyms);
  //     setLoading(!loading);
  //   }
  // };

  const updateGymList = (data, loading, total = 0) => {
    setListGyms(data);
    setTotalRecords(total);
    setHaveFeatured(featuredExists(data));
    setLoading(loading);
  }

  // useEffect(() => {
  //   fetchGyms();
  // }, []);
  return (
    <>
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-6">
              <div className="title d-flex flex-column justify-content-center align-items-center">
                <h3 className="heading text-center">GymElite</h3>
                <p className="text-center">Explore gyms near you that you can join</p>
                {creationAllowed &&
                  <Button type="button" variant="primary btn-pill" value="Add Gym" onClick={() => navigate('/gym/add')} text="Add Gym" />
                }
              </div>
              <form onSubmit={handleSubmit}>
                <InputField type="text" name="search"
                  onChange={(e) => { setSearchText(e.target.value !== undefined ? e.target.value : '') }}
                  value={searchText} />
              </form>
            </div>
            <GymSearchFilter handleGymList={updateGymList} />
            <div className="col-lg-12">
              <div className="row justify-content-start serviceBoxes">
                {
                  haveFeatured && <>
                    <div className="text-center mb-40px">
                      <div className="font-size-32px fw-bold color-text-primary">Featured</div>
                    </div>

                    {listGyms.length > 0 ? listGyms?.map((item, index) => {
                      return (
                        <div className={`col-lg-6 col-6 ${item.isFeatured ? 'd-flex' : 'd-none'}`} key={index}>
                          <Cta
                            eventName={item.name}
                            icon={endPoint.url + item.image}
                            region={item.location}
                            city={item.city}
                            price={`£ ${item.price}`}
                            goodfor={item.features}
                            // btnText="See details"
                            link={`/gym/${item._id}`}
                          />
                        </div>
                      );
                    })
                      : <></>}
                    <Separator />
                  </>
                }
                <div className='col-lg-12'>
                  <div className='row'>
                    {loading && <Spinner />}
                    {listGyms.length > 0 ? listGyms?.map((item, index) => {
                      return (
                        <div className={`col-lg-6 col-6 ${!item.isFeatured ? 'd-flex' : 'd-none'}`} key={index}>
                          <Cta
                            eventName={item.name}
                            icon={endPoint.url + item.image}
                            region={item.location}
                            city={item.city}
                            price={`£ ${item.price}`}
                            goodfor={item.features}
                            // btnText="See details"
                            link={`/gym/${item._id}`}
                          />
                        </div>
                      );
                    })
                      :
                      <div className="text-center">
                        <div className="font-size-32px fw-bold color-text-primary">{loadingMessage}</div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalRecords}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default GymEliteScreen;
