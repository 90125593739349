import React, { useEffect, useState } from 'react';
import Blurb from '../molecules/Blurb/Index';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import BackButton from '../atoms/Button/BackButton';
import Spinner from '../atoms/Spinner';
const BuildEliteScreen = () => {
  const [loading, setLoading] = useState(true);
  const [subCategories, setSubCategories] = useState();

  const fetchCategories = async () => {
    let data = await axiosWrapper.get(`${endPoint.host}/categories`);
    if (data.success) {
      data.data.find((item) => {
        if (item.name === 'BuildElite') {
          setSubCategories(item.subcategories);
          setLoading(!loading);
        }
      });
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-12">
              <div className="title">
                <h3 className="heading text-center font-size-64px">BuildElite</h3>
                <p className="text-center font-size-32px">Find your discipline</p>
              </div>
            </div>

            {loading && <Spinner />}
            <div className="col-lg-10">
              <div className="row justify-content-center serviceBoxes">
                {subCategories?.map((item, index) => {
                  return (
                    <div className="col-lg-6" key={index}>
                      <Blurb
                        title={item.name}
                        description={item.description}
                        bgImage={endPoint.url + item.image}
                        link={`/${item.name.toLowerCase().replace(/\s/g, '')}/${item._id}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BuildEliteScreen;
