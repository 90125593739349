import React from 'react';
import { NavLink } from 'react-router-dom';

const Blurb = (props) => {
  const { bgImage, link, title, description } = props;
  return (
    <>
      <NavLink to={link}>
        <figure
          className="box box1 rounded-60px"
          style={{
            background: `url(${bgImage}) no-repeat center center / cover`,
          }}
        >
          <div className='container'>
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <figcaption className="figTitle">{title}</figcaption>
                <figcaption className="figContent">{description}</figcaption>
              </div>
            </div>
          </div>
        </figure>
      </NavLink>
    </>
  );
};

export default Blurb;
