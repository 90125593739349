import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../../atoms/Button/BackButton';
import Spinner from '../../atoms/Spinner';
import downArrow from '../../assets/img/ic-down.svg';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import shieldIcon from '../../assets/img/icons/shield.svg';
import icInfo from '../../assets/img/ic-info.svg';
import fbIcon from '../../assets/img/icons/fb.svg';
import linkedinIcon from '../../assets/img/icons/linkedin.svg';
import instaIcon from '../../assets/img/icons/insta.svg';
import twitterIcon from '../../assets/img/icons/twitter.svg';
import heartIcon from '../../assets/img/ic-heart.svg';
import icHeartFilled from '../../assets/img/heart-fill.svg';
import placeholder from '../../assets/img/placeholder.svg';
import ReviewScreen from '../Gym/ReviewScreen';
import ClientHighlights from '../Gym/ClientHighlights';
import RatingComponent from '../../config/rating';
import toaster from '../../config/toaster';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from '../../util';
const TrainerProfile = () => {
  const { id } = useParams();

  const [profile, setProfile] = useState({});
  const [showRating, setShowRating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  
  const fetchProfile = async () => {
    let result = await axiosWrapper.get(`${endPoint.host}/${id}`, endPoint.header);
    if (result.success) {
      console.log(result);
      if (result?.data) {
        result.data.sumAvailabilityRating = 0;
        result.data.sumProfessionalismRating = 0;
        result.data.sumValueMoneyRating = 0;
        result.data.sumSupportRating = 0;
        result.data.sumResponseTimeRating = 0
        if (result.data.reviews?.length) {
          result.data.reviews.forEach(ele => {
            result.data.sumAvailabilityRating += ele.availabilityRating;
            result.data.sumProfessionalismRating += ele.professionalismRating;
            result.data.sumValueMoneyRating += ele.valueMoneyRating;
            result.data.sumSupportRating += ele.supportRating;
            result.data.sumResponseTimeRating += ele.responseTimeRating;
          });
        }
        result.data.sumAvailabilityRating = Number((Number(result.data.sumAvailabilityRating) / result.data.reviews.length).toFixed());
        result.data.sumProfessionalismRating = Number((Number(result.data.sumProfessionalismRating) / result.data.reviews.length).toFixed());
        result.data.sumValueMoneyRating = Number((Number(result.data.sumValueMoneyRating) / result.data.reviews.length).toFixed());
        result.data.sumSupportRating = Number((Number(result.data.sumSupportRating) / result.data.reviews.length).toFixed());
        result.data.sumResponseTimeRating = Number((Number(result.data.sumResponseTimeRating) / result.data.reviews.length).toFixed());
        result.data.avgRating = Number(((result.data.sumAvailabilityRating + result.data.sumProfessionalismRating + result.data.sumValueMoneyRating + result.data.sumSupportRating + result.data.sumResponseTimeRating) / 5).toFixed())
        console.log(result.data)
        setProfile(result.data);
        setLoading(false);
        setFavoriteLoading(false);
      } else {
        setProfile(result.data);
        setLoading(false);
        setFavoriteLoading(false);
      }
    }
  };

  const onAddToFavorite = async () => {
    setFavoriteLoading(true);
    const postData = {
      isFavorite: profile.isFavorite ? false : true,
    }
    let result = await axiosWrapper.updatePatch(`${endPoint.host}/${id}/favorite`, postData, endPoint.header);
    if (result.success) {
      toaster(`${postData.isFavorite ? 'success' : 'error'}`, `Coach ${postData.isFavorite ? 'added to' : 'removed from'} favorite`);
      fetchProfile();
    }
  }

  const reviews = profile?.reviews;
  useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <>
      {loading ? (
        <div style={{ padding: '100px' }}>
          <Spinner />
        </div>
      ) : (
        <>
          <ToastContainer />
          <section className='pt-40px'>
            <div className="container">
              <div className="row justify-content-center">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <BackButton />
                </div>
                <div className="col-11">
                  <div className="title d-flex justify-content-between align-items-center">
                    <div>
                      <img alt="" src={icInfo} className="img-fluid" />
                    </div>
                    <div>
                      <h3 className="heading text-center">
                        <span>
                          <img alt="" src={shieldIcon} />
                        </span>
                        {profile.fullname}
                      </h3>
                      <p className="text-center">{profile.company}</p>
                    </div>
                    <div>
                      {/* <img alt="" src={heartIcon} className="img-fluid" /> */}
                      {favoriteLoading ? <Spinner /> :
                        <img
                          src={profile.isFavorite ? icHeartFilled : heartIcon}
                          className="img-fluid cursor-pointer"
                          style={{ width: '40px' }}
                          alt="favorite icon" onClick={onAddToFavorite}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className="col-11 mb-100px">
                  <div className="row justify-content-center serviceBoxes">
                    <div className="col-lg-12">
                      <div className="card card-custom border-0 p-0">
                        <div className="card-body p-0">
                          <div className="row">
                            <div className="col-lg-5">
                              <img
                                alt=""
                                src={
                                  (profile.profilePic && endPoint.url + profile.profilePic) ||
                                  placeholder
                                }
                                width="400px"
                              />
                            </div>
                            <div className="col-lg-1" />
                            <div className="col-lg-6">
                              {profile.userSubscription?.subscriptionId?.isEnhancedProfile && (
                                <p className="text-start">{profile.description}</p>
                              )}
                              <table className="table table-borderless">
                                <tbody>
                                  <tr>
                                    <th className="font-size-24px">
                                      <div id="rating_toggle">Rating
                                        <span>
                                          <img alt="arrow down" onClick={() => setShowRating(!showRating)} src={downArrow} className="img-fluid" />
                                        </span>
                                      </div>
                                    </th>
                                    <td className="text-end font-size-20px">
                                      <RatingComponent ratingCount={profile.avgRating} />
                                    </td>
                                  </tr>
                                  {showRating && (
                                    <tr style={{ display: "table-row" }}>
                                      <td colSpan="2">
                                        <div className="bg-light p-3">
                                          <table className="table table-sm table-borderless align-middle m-0">
                                            <tbody><tr>
                                              <th className="font-size-14px">Overall</th>
                                              <td className="text-end font-size-20px">

                                              </td>
                                            </tr>
                                              <tr>
                                                <th className="font-size-14px">Value for money</th>
                                                <td className="text-end font-size-20px">
                                                  <RatingComponent ratingCount={profile?.sumValueMoneyRating} />
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="font-size-14px">Professionalism</th>
                                                <td className="text-end font-size-20px">
                                                  <RatingComponent ratingCount={profile?.sumProfessionalismRating} />
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="font-size-14px">All-through support</th>
                                                <td className="text-end font-size-20px">
                                                  <RatingComponent ratingCount={profile?.sumSupportRating} />
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="font-size-14px">Response Time</th>
                                                <td className="text-end font-size-20px">
                                                  <RatingComponent ratingCount={profile?.sumResponseTimeRating} />
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="font-size-14px">Availability</th>
                                                <td className="text-end font-size-20px">
                                                  <RatingComponent ratingCount={profile?.sumAvailabilityRating} />
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <th className="font-size-24px">Location</th>
                                    <td className="text-end font-size-20px">{profile.location}</td>
                                  </tr>
                                  <tr>
                                    <th className="font-size-24px">Specialisations</th>
                                    <td className="text-end font-size-20px">
                                      {profile.specialties?.map((item, index) => {
                                        return (
                                          <div key={index}>
                                            {item.name}
                                            <br />
                                          </div>
                                        );
                                      })}
                                    </td>
                                  </tr>
                                  {profile.userSubscription?.subscriptionId?.isEnhancedProfile && (
                                    <tr>
                                      <th className="font-size-24px">Price</th>
                                      <td className="text-end font-size-20px">£ {profile.price}</td>
                                    </tr>
                                  )}

                                  <tr>
                                    <th className="font-size-24px">Social media</th>
                                    <td className="text-end font-size-20px">
                                      <a className='me-2' href={profile.facebookUrl}>
                                        <img alt="" src={fbIcon} />
                                      </a>
                                      <a className='me-2' href={profile.instagramUrl}>
                                        <img alt="" src={instaIcon} />
                                      </a>
                                      <a className='me-2' href={profile.linkedinUrl}>
                                        <img alt="" src={linkedinIcon} />
                                      </a>
                                      <a className='me-2' href={profile.twitterUrl}>
                                        <img alt="" src={twitterIcon} />
                                      </a>
                                    </td>
                                  </tr>

                                  {profile.userSubscription?.subscriptionId?.isEnhancedProfile && profile.relatedServices.length ? (
                                    <>
                                      <tr>
                                        <th className="font-size-24px">Operating since</th>
                                        <td className="text-end font-size-20px">
                                          {profile.operatingSince}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="font-size-24px">Other related services</th>
                                        <td className="text-end font-size-20px">
                                          {profile.relatedServices?.map((item, index) => {
                                            return (
                                              <div key={index}>
                                                {item} <br></br>
                                              </div>
                                            );
                                          })}
                                        </td>
                                      </tr>
                                    </>
                                  ) : <></>}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {profile.userSubscription?.subscriptionId?.isEnhancedProfile && profile.usertype === 'COACH' && (
            <ClientHighlights data={profile?.clients} />
          )}
          {profile.userSubscription?.subscriptionId?.reviewsAllowed && <ReviewScreen data={reviews} route={ROUTES.TRAINER_PROFILE} />}


        </>
      )}
    </>
  );
};

export default TrainerProfile;
