import Frontend from './templates/Frontend/Index';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Frontend />
    </>
  );
}

export default App;
