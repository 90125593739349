import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import BackButton from '../atoms/Button/BackButton';
import Button from '../atoms/Button/Index';
import InputField from '../atoms/Input/Index';
import SocialIcons from '../atoms/Social/SocialIcons';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import toaster from '../config/toaster';


const ContactScreen = () => {
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
    phone: '',
  });

  const [contactMethod, setContactMethod] = useState([]);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await axiosWrapper.post(`${endPoint.host}/contact`, {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      message: values.message,
      contactMethod: contactMethod
    });

    if (result.success) {
      setValues({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        phone: '',
      });
      setContactMethod([])
      toaster('success', result.message)
    } else {
      toaster('error', result.message)
    }
  };


  const handleMethodSelect = (event) => {
    let check = event.target.checked;
    let checked_method = event.target.value;

    if (check) {
      setContactMethod([...contactMethod, checked_method])
    } else {
      var index = contactMethod.indexOf(checked_method);
      contactMethod.splice(index, 1);
    }
  }

  return (
    <>
      <ToastContainer />

      <section className="pt-40px">
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-6">
              <div className="title">
                <h3 className="heading text-center font-size-64px">Contact Us</h3>
                <p className="text-center">Visit our social media or contact us using the form below.</p>
                <SocialIcons fill={true} />
              </div>
              <form onSubmit={handleSubmit} className="contactForm">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input value={values.firstname} className="form-control" name='firstname' onChange={handleChange('firstname')} type="text" placeholder="First Name" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input value={values.lastname} className="form-control" name='lastname' onChange={handleChange('lastname')} type="text" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input value={values.email} className="form-control" name='email' onChange={handleChange('email')} type="text" placeholder="Email Address" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input value={values.phone} className="form-control" name='phone' onChange={handleChange('phone')} type="text" placeholder="Phone Number" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Your message</label>
                  <textarea
                    rows={5}
                    value={values.message}
                    onChange={handleChange('message')}
                    className="form-control rounded-2"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-2">Preferred method of contact</label><br />
                  <div className="form-check form-check-inline">
                    <input onChange={handleMethodSelect} defaultValue="EMAIL" className="form-check-input rounded-0" type="checkbox" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Email
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={handleMethodSelect} className="form-check-input rounded-0" type="checkbox" defaultValue="CALL" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Call
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input onChange={handleMethodSelect} className="form-check-input rounded-0" type="checkbox" defaultValue="TEXT" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Text
                    </label>
                  </div>
                </div>
                <div className="text-center mt-40px">
                  <Button type="submit" text="Send" variant="primary btn-pill" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default ContactScreen;
