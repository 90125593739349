import React from 'react';
import Button from '../Button/Index';
import placeholderImage from '../../assets/img/placeholder.svg';
import { Link } from 'react-router-dom';

const Cta = (props) => {
  const { eventName, price, goodfor, icon, region, date, btnText, link, city } = props;
  return (
    <>
      <Link to={link} className="text-dark">
        <div className="card card-custom border-0 mb-4">
          <div className="card-body">
          <div className="d-flex gap-20px">
              <div className="card-custom-img">
                <img className="img-fluid w-full" src={icon} alt={eventName} />
              </div>
              <div className="card-content">
                <h4 className="m-0 font-size-24px fw-bold">{eventName}</h4>
                <p className="m-0">
                  <strong>Location:</strong> {region}
                </p>
                <p className="m-0">
                  <strong>City:</strong> {city !== "" ? city : "N/A"}
                </p>
                <p className="m-0">
                  {price ? <strong>Price:</strong> : ''} {price}
                </p>

                <p className="m-0">
                  {date ? <strong>Date:</strong> : ''} {date}
                </p>

                {goodfor ? <strong>Good for : </strong> : ''}

                <br />
                {goodfor?.map((item, index) => {
                  return (
                    <div className="pb-1" key={index}>
                      <span>{item}</span>
                    </div>
                  );
                })}
                {btnText ? <Button link={link} variant="primary btn-pill" text={btnText} /> : ""}

              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

Cta.defaultProps = {
  icon: placeholderImage,
  label: 'Button Text',
};
export default Cta;
