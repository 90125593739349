import axios from "axios";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "../atoms/Button/Index";
import InputField from "../atoms/Input/Index";
import endPoint from "../config/endPoints";
import toaster from "../config/toaster";

const InviteModal = ({ handleShow, handleClose, show }) => {

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || email === "") {
      toaster('error', 'All fields are required')
      return false;
    }

    const postData = {
      email,
      name
    }

    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };

    let result = await axios.post(`${endPoint.host}/invite`, postData, config);

    if (result.data.success) {
      toaster('success', result.data.message)
      handleClose();
    } else {
      toaster('error', result.message)
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form>
              <div className="form-group">
                <label>Name</label>
                <InputField
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  required
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <InputField
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  required
                />
              </div>


            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} text="Close" />
          <Button variant="primary" type="submit" text="Save Changes" onClick={handleSubmit} />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InviteModal;
