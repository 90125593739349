// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import Snackbar from '../atoms/Snackbar/Index';

import { logEvent, ErrorResult } from '../util';
import '../styles/common.css';
import axiosWrapper from '../config/axiosWrapper';
import endPoint from '../config/endPoints';
import { useNavigate } from "react-router-dom"

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      postal: '',
      coupon: '',
      errorMessage: null,
      paymentMethod: null,
      error: false,
      userType: localStorage.getItem('newUserType') ? localStorage.getItem('newUserType') : ""
    };
  }
  onCancelError = async () => {
    this.setState({
      error: false
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    const { name, postal, coupon, userType } = this.state;
    const { id } = this.props;
    const { navigate } = this.props;
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name,
        address: {
          postal_code: postal,
        },
      },
    });

    if (payload.error) {
      console.log('[error]', payload.error);
      this.setState({
        errorMessage: payload.error.message,
        paymentMethod: null,
        error: true
      });
    } else {
      console.log('[PaymentMethod]', payload.paymentMethod);
      try {
        let result = await axiosWrapper.post(`${endPoint.host}/subscription/live/setup`, {
          subscriptionId: id,
          paymentId: payload.paymentMethod.id,
          coupon: coupon,
          userType
        },
          {
            Authorization: localStorage.getItem('token'),
          });
        if (result.success) {
          console.log('[success]', result.success);
          localStorage.setItem('subscription', JSON.stringify(result.data?.userSubscription?.subscriptionId));
          localStorage.removeItem('newUserType');
          navigate(`/`)
        }
      } catch (error) {
        console.log('[error]', error);
        this.setState({
          errorMessage: error.response.data.message,
          paymentMethod: null,
          error: true
        });
      }

    }
  };

  render() {
    const { stripe } = this.props;
    const { postal, name, paymentMethod, errorMessage, error, coupon } = this.state;
    return (
      <>
        {error ? <Snackbar message={errorMessage} type="alert" onClick={this.onCancelError} /> : ''}
        <form className='stripeCardUI' onSubmit={this.handleSubmit}>
          <label htmlFor="name">Full Name</label>
          <input
            id="name"
            required
            placeholder="Jenny Rosen"
            value={name}
            onChange={(event) => {
              this.setState({ name: event.target.value });
            }}
          />
          <label htmlFor="cardNumber">Card Number</label>
          <CardNumberElement
            id="cardNumber"
            onBlur={logEvent('blur')}
            onChange={logEvent('change')}
            onFocus={logEvent('focus')}
            onReady={logEvent('ready')}
            options={ELEMENT_OPTIONS}
          />
          <label htmlFor="expiry">Card Expiration</label>
          <CardExpiryElement
            id="expiry"
            onBlur={logEvent('blur')}
            onChange={logEvent('change')}
            onFocus={logEvent('focus')}
            onReady={logEvent('ready')}
            options={ELEMENT_OPTIONS}
          />
          <label htmlFor="cvc">CVC</label>
          <CardCvcElement
            id="cvc"
            onBlur={logEvent('blur')}
            onChange={logEvent('change')}
            onFocus={logEvent('focus')}
            onReady={logEvent('ready')}
            options={ELEMENT_OPTIONS}
          />
          <label htmlFor="postal">Postal Code</label>
          <input
            id="postal"
            required
            placeholder="12345"
            value={postal}
            onChange={(event) => {
              this.setState({ postal: event.target.value });
            }}
          />
          <label htmlFor="name">Coupon</label>
          <input
            id="coupon"
            placeholder="Coupon"
            value={coupon}
            onChange={(event) => {
              this.setState({ coupon: event.target.value });
            }}
          />
          {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
          {/* {paymentMethod && (
          <Result>Got PaymentMethod: {paymentMethod.id}</Result>
        )} */}
          <button type="submit" disabled={!stripe}>
            Pay
          </button>
        </form>
      </>
    );
  }
}

const StripeSplitCard = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} id={id} navigate={navigate} />
      )}
    </ElementsConsumer>
  );
};


export default StripeSplitCard;
