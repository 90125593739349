import axios from 'axios';

const get = async (url, headers) => {
  headers = {
    Authorization: localStorage.getItem("token")
  }
  const payload = {
    method: 'get',
    url: url,
    headers
  };

  const response = await axios(payload);
  return response.data;
};
const post = async (url, data, headers) => {
  headers = {
    Authorization: localStorage.getItem("token")
  }
  const payload = {
    method: 'post',
    url: url,
    data,
    headers,
  };

  const response = await axios(payload);
  return response.data;
};

const destroy = async (url, data) => {
  const payload = {
    method: 'delete',
    url: url,
    data,
  };

  const response = await axios(payload);
  return response.data;
};

const updatePut = async (url, data) => {
  const payload = {
    method: 'put',
    url: url,
    data,
  };

  const response = await axios(payload);
  return response.data;
};

const updatePatch = async (url, data, headers) => {
  headers = {
    Authorization: localStorage.getItem("token")
  }
  const payload = {
    method: 'patch',
    url: url,
    data,
    headers
  };

  const response = await axios(payload);
  return response.data;
};

const wrapper = { get, post, updatePut, updatePatch, destroy };
export default wrapper;
