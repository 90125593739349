import React, { useState } from 'react';
import Button from '../../atoms/Button/Index';
import logo from '../../assets/img/logo-white.png';
import Image from '../../atoms/Image/Index';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const Header = () => {
  let menuItems = [
    {
      path: '/buildelite',
      label: 'BuildElite',
    },
    {
      path: '/fightelite',
      label: 'FightElite',
    },
    {
      path: '/gymelite',
      label: 'GymElite',
    },
    {
      path: '/events',
      label: 'Events',
    },
    {
      path: '/about',
      label: 'About',
    },
    {
      path: '/contact',
      label: 'Contact',
    },
  ];
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    setIsNavCollapsed(!isNavCollapsed);
    localStorage.removeItem("usertype")
    localStorage.removeItem("token")
    localStorage.removeItem("_id");
    window.location.reload();
    navigate("/login")
  }

  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const onLogin = () => {
    setIsNavCollapsed(false);
    navigate('/login');
  }

  let token = localStorage.getItem('token');
  return (
    <>

      <header>
        <nav className="navbar navbar-expand-lg navbar-dark color-dark">
          <div className="container">

            <NavLink to="/" className="navbar-brand order-xs-2" onClick={() => setIsNavCollapsed(false)}>
              <Image path={logo} className="img-fluid" altText="ProAthelite" />
            </NavLink>

            <div className="order-xs-1">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                onClick={handleNavCollapse} aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false}
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className={`${isNavCollapsed ? 'collapse show' : 'collapse'} navbar-collapse`}
                id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  {menuItems.map((item, index) => {
                    return (
                      <li className="nav-item js-scroll-trigger" id="nav-item js-scroll-trigger" key={index} onClick={handleNavCollapse}>
                        <NavLink
                          className={({ isActive }) => `nav-link js-scroll-trigger ${isActive ? 'active' : ''}`}
                          to={item.path}
                        >
                          {item.label}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {token ? (
              <>
                <div className="order-xs-2 dropdown">
                  <a className="btn btn-pill btn-outline-primary text-white dropdown-toggle" href="?" role="button" data-bs-toggle="dropdown" aria-expanded="false">Profile</a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <Link
                        className='b dropdown-item'
                        to='/profile'
                        onClick={handleNavCollapse}
                      >
                        Profile
                      </Link>
                    </li>
                    <li><a onClick={handleLogout} className="dropdown-item" href="?" >Log out</a></li>
                  </ul>
                </div>
              </>

            ) : (
              <div className='order-xs-2'>
                <Button text="Log in" variant="primary btn-pill" onClick={onLogin} />
              </div>
            )}


          </div>
        </nav>
      </header>


    </>
  );
};

export default Header;
