import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
        className="d-flex align-items-center gap-10px mb-5"
      >
        <i className="fi fi-rr-arrow-small-left align-self-center d-flex h4 m-0" /> Back
      </div>
    </>
  );
};

export default BackButton;
