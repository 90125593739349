import axios from "axios";
import React, { useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import BackButton from "../../atoms/Button/BackButton";
import Button from "../../atoms/Button/Index";
import InputField from "../../atoms/Input/Index";
import Spinner from "../../atoms/Spinner";
import endPoint from "../../config/endPoints";
import toaster from "../../config/toaster";

const CheckInput = styled.input`
  width: 25px;
  height: 25px;
`;

const AddEvent = () => {

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [eventPic, setEventPic] = useState('');
  const [profilePicName, setProfilePicName] = useState('');
  const [eventData, setEventData] = useState({});
  const [location, setLocation] = useState({});

  const handleImageChange = (e) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]));
    setEventPic(e.target.files[0]);
    setProfilePicName('uploads/' + e.target.files[0].name);
    setEventData({
      ...eventData,
      image: e.target.files[0]
    });
  }

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    setEventData({ ...eventData, [name]: value });
  }

  const onChangeLocation = (place) => {
    setLocation(
      {
        address: place.formatted_address,
        city: place.address_components[1].long_name
      }
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const form = new FormData();
    form.append('image', eventPic);
    form.append('name', eventData.name);
    form.append('description', eventData.description);
    form.append('address', location.address);
    form.append('city', location.city);
    form.append('startTime', eventData.startTime);
    form.append('endTime', eventData.endTime);
    form.append('postalCode', eventData.postalCode);
    form.append('url', eventData.url);
    form.append('notes', eventData.notes);

    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };

    let result = await axios.post(`${endPoint.host}/event`, form, config);
    if (result.data.success) {
      setLoading(false);
      navigate('/events');
    }
    else {
      toaster('error', 'Something went wrong');
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading ? <div style={{ padding: "100px" }}>
        <Spinner />
      </div> :
        <>
          <ToastContainer />
          <section className='pt-40px'>
            <div className="container">
              <div className="row justify-content-center">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <BackButton />
                </div>

                <div className="col-lg-5">
                  <form onSubmit={handleSubmit} className="addEvent">
                    <div className="container">
                      <h4>Event Picture
                      </h4>
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input type="file" onChange={handleImageChange} id="imageUpload" accept=".png, .jpg, .jpeg" />
                          <label htmlFor="imageUpload" />
                        </div>
                        <div className="avatar-preview">
                          <div id="imagePreview" style={{ backgroundImage: imgSrc ? `url(${imgSrc})` : `url(${endPoint.url + eventData?.image})` }}>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Name</label>
                      <InputField
                        onChange={onChangeInput}
                        name="name"
                        value={eventData?.name}
                        type="text"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        onChange={onChangeInput}
                        name="description"
                        value={eventData?.description}
                        type="text"
                        className="form-control"
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <label>Address</label>
                      </div>
                      <ReactGoogleAutocomplete
                        placeholder="Address"
                        className='form-control'
                        name="address"
                        defaultValue={location?.address}
                        apiKey={endPoint.locationApiKey}
                        onPlaceSelected={(place) => {
                          onChangeLocation(place)
                        }}
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>City</label>
                      <InputField
                        onChange={onChangeInput}
                        name="city"
                        value={location?.city}
                        type="text"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Postal Code</label>
                      <InputField
                        onChange={onChangeInput}
                        name="postalCode"
                        value={eventData?.postalCode}
                        type="text"
                        isRequired={true}
                      />
                    </div>


                    <div className="form-group">
                      <label>Start Time</label>
                      <InputField
                        onChange={onChangeInput}
                        name="startTime"
                        value={eventData?.startTime}
                        type="datetime-local"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>End Time</label>
                      <InputField
                        onChange={onChangeInput}
                        name="endTime"
                        value={eventData?.endTime}
                        type="datetime-local"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Event URL</label>
                      <InputField
                        onChange={onChangeInput}
                        name="url"
                        value={eventData?.url}
                        type="text"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Event Notes</label>
                      <InputField
                        onChange={onChangeInput}
                        name="notes"
                        value={eventData?.notes}
                        type="text"
                        isRequired={true}
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="form-group m-0">
                        <div className="form-check d-flex flex-row align-items-center p-0 gap-2">
                          <CheckInput
                            className="mx-2 form-check-input rounded-0 my-0 cursor-pointer"
                            type="checkbox"
                            name="isFeatured"
                            id="isFeatured"
                            defaultValue
                            checked={eventData?.isFeatured}
                            onChange={(e) => setEventData({ ...eventData, isFeatured: e.target.checked ? true : false })}
                          />
                          <label className="form-check-label m-0 text-lg cursor-pointer" htmlFor="isFeatured">Featured</label>
                        </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Button text="Add Event" type="submit" variant="primary btn-pill" />
                    </div>

                  </form>
                </div>

              </div>
            </div>
          </section>
        </>
      }
    </>
  );
}

export default AddEvent;
