import React, { useState, useEffect } from 'react';
import filledStar from '../../assets/img/icons/ic-filled-star.svg';
import Star from '../../assets/img/icons/ic-star.svg';
import Button from '../../atoms/Button/Index';
import endPoint from '../../config/endPoints';
import SubmitReview from './SubmitReview';
import TrainerSubmitReview from '../trainer/SubmitReview';
import placeholder from '../../assets/img/placeholder.svg';
import Rating from '../../atoms/Rating';
import { ROUTES } from '../../util';


const ReviewScreen = ({ data, route }) => {
  data.forEach(ele => ele.isRatingColapse = false)
  // let path = '';
  let path_name = route;

  useEffect(() => {
    // path = window.location.pathname;
    // path_name = route;
    console.log('path_name', path_name);
  }, [])

  const handleVisibility = () => {
    setIsVisible(false)
  }

  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="title d-flex justify-content-between align-items-center">
                <div />
                <div>
                  <h3 className="heading text-center">Reviews</h3>
                </div>
                <div>
                  <Button
                    text="Rate and review"
                    variant="primary btn-pill"
                    onClick={() => setIsVisible(!isVisible)}
                  />
                </div>
              </div>
            </div>

            {isVisible && (
              <div className="col-12">
                {path_name === '/trainer_profile' ?
                  <TrainerSubmitReview /> :
                  <SubmitReview handleVisibility={handleVisibility} />
                }
              </div>
            )}
            {data?.length > 0 ? (
              <div className="col-12 mb-100px">
                {data?.map((item, index) => {
                  return (
                    <div key={index} className="row justify-content-center serviceBoxes py-3">
                      <div className={path_name === '/trainer_profile' ? 'col-lg-8' : "col-lg-3"}>
                        <div className="d-flex align-items-center gap-20px">
                          <img
                            style={{ width: "100px", height: "100px" }}
                            className="rounded-circle"
                            src={item.reviewerId?.profilePic ? endPoint.url + item.reviewerId?.profilePic : placeholder}
                            alt="user profile pic"
                          />
                          <div>
                            <h4 className="font-size-20px fw-bold">
                              {item?.userId?.fullname || item?.reviewerId?.fullname}
                            </h4>
                            <div>
                              <Rating rating={item.overallRating} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={path_name === '/trainer_profile' ? 'col-lg-8' : "col-lg-6"}>
                        {item.commentStatus !== "ACCEPT" ? "" : <p className="p-3">{item.comment}</p>}
                      </div>

                      <div className="col-lg-3">
                        <RenderRating item={item} type={path_name} />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              'No reviews available'
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewScreen;


function RenderRating({ item, type }) {

  return (
    <table className="table table-borderless">
      <tbody>
        <tr>
          <RatingItem label="Overall" value={item.overallRating} />
        </tr>
        <tr>
          <RatingItem label="Value for money" value={item.valueMoneyRating} />
        </tr>
        <tr>
          <RatingItem
            label={type === ROUTES.TRAINER_PROFILE ? "Professionalism" : "Equipment"}
            value={type === ROUTES.TRAINER_PROFILE ? item.professionalismRating : item.equipmentRating} />
        </tr>
        <tr>
          <RatingItem
            label={type === ROUTES.TRAINER_PROFILE ? "Response Time" : "Hygenic"}
            value={type === ROUTES.TRAINER_PROFILE ? item.responseTimeRating : item.hygenicRating} />
        </tr>
        <tr>
          <RatingItem
            label={type === ROUTES.TRAINER_PROFILE ? "Support" : "Facilities"}
            value={type === ROUTES.TRAINER_PROFILE ? item.supportRating : item.facilitiesRating} />
        </tr>
        <tr>
          <RatingItem
            label={"Availability"}
            value={item.availabilityRating} />
        </tr>
      </tbody>
    </table>
  )
}

function RatingItem({ label, value }) {
  return (
    <>
      <th>{label}</th>
      <th className="text-end">{value || 0}/5</th>
    </>
  );
}