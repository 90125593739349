import moment from "moment";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components";
import Button from "../atoms/Button/Index";
import endPoint from "../config/endPoints";

const StyledImage = styled.img`
  max-width: 100%;
  height: 200px;
  border-radius: 10px;
  background-size: cover;
`;

const DiscountModal = ({ handleShow, handleClose, show, data }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Discount Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <StyledImage src={`${endPoint.url}/${data.image}`} alt='discount icon' />
            <span className='font-bold'>{data.description}</span>
            <span>Coupon: <span className='font-bold'>{data.code}</span></span>
            <span>Starts on: {moment(data.startDate).format('YYYY-MM-DD HH:mm A')}</span>
            <span>Expires on: {moment(data.expiryDate).format('YYYY-MM-DD HH:mm A')}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} text="Close" />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DiscountModal;
