import React from 'react';
import Carousel from 'react-elastic-carousel';
import endPoint from '../../config/endPoints';
import placeholder from '../../assets/img/placeholder.svg'
const ClientHighlights = (props) => {
  const { data } = props;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
  ];

  return (
    <section className="featured" id="clientHighlight">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="title">
              <h3 className="heading text-center">Client highlights</h3>
            </div>
          </div>
          <div className="col-lg-12">
            {data.length < 1 ?
              <div className='alert alert-info text-center'>No highlights available</div>
              :
              <Carousel
              focusOnSelect={true}
              itemsToShow={1}
              pagination={false}
              breakPoints={breakPoints}
            >
              {data?.map((item, index) => {
                return (
                  <div key={index} className="card m-3 card-custom text-center">
                    <div className="card-icon">
                      <img style={{ width: "180px", height: "150px", objectFit: 'cover' }} src={endPoint.url + item.image || placeholder} alt="" />
                    </div>
                    <div className="card-title">{item.name}</div>
                    <div className="card-content">{item.description}</div>
                  </div>
                );
              })}
            </Carousel>
            }
          </div>
        </div>
      </div>

    </section>
  );
};

export default ClientHighlights;
