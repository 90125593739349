import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Index';
import InputField from '../../atoms/Input/Index';
import Snackbar from '../../atoms/Snackbar/Index';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import Spinner from '../../atoms/Spinner/index';
import { ToastContainer } from 'react-toastify';
import toaster from '../../config/toaster';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const handleClose = () => {
    setError(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let result = await axiosWrapper.post(`${endPoint.host}/login`, {
        email,
        password,
      });

      if (result.success) {
        setLoading(false);
        localStorage.setItem('token', result.data.user.token);
        localStorage.setItem('subscription', JSON.stringify(result.data?.user?.userSubscription?.subscriptionId));
        localStorage.setItem('usertype', result.data.user.usertype);
        localStorage.setItem('_id', result.data.user._id);
        if (result.data.subscriptionSignup === 1) {
          if (result.data.user.type === 'INDIVIDUAL') {
            navigate('/subscription-individual');
          } else {
            navigate('/subscription-coach');
          }
        } else {
          navigate('/');
        }
      } else {
        setLoading(false);
        toaster('error', result.message)
      }
    } catch (error) {
      setLoading(false);
      toaster('error', error.response.data.message)
    }
  };
  return (
    <>
      <ToastContainer />

      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="title text-center">
                <p className="font-size-20px fw-bold">
                  Don’t have an account? <NavLink className="color-text-primary d-block text-decoration-underline mb-60px" to="/register">Register now!</NavLink>
                </p>
                <h3 className="heading">Log in</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <InputField
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email address..."
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>

                  <InputField
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password..."
                  />
                </div>
                <div className="form-group">
                  <Link to="/forgot" className='text-decoration-underline fw-bold'>
                    Forgot your password?
                  </Link>
                </div>
                <div className="text-center">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Button type="submit" variant="primary btn-pill fw-bold" text="Log in" />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginScreen;
