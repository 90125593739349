import React from 'react';
import { Link } from 'react-router-dom';
import FooterLogo from "../assets/img/footer-logo.png";
import SocialIcons from '../atoms/Social/SocialIcons';
const FooterScreen = () => {
  return (
    <>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center mb-100px">
              <img src={FooterLogo} alt="pro athelete" className="img-fluid" />
            </div>
            <div className="col-lg-4 text-center">
              <h3 className="text-white footer-title">Contact</h3>
              <p className="text-white"><b>Email:</b> contact@pro-athelite.com</p>
              {/* <p className="text-white"><b>Call:</b> +44 759 797 3299</p> */}
            </div>
            <div className="col-lg-4 text-center">
              <h3 className="text-white footer-title">Links</h3>
              <p><Link className='text-white' to="terms-and-conditions">Terms and Conditions</Link></p>
              <p><Link className='text-white' to="privacy-policy">Privacy Policy</Link></p>
            </div>
            <div className="col-lg-4 text-center">
              <h3 className="text-white footer-title">Social Media</h3>
              <SocialIcons />
            </div>
          </div>
        </div>
      </footer>

    </>
  );
};

export default FooterScreen;
