import React from 'react';
import Button from '../../atoms/Button/Index';
import InviteModal from '../../popups/InviteModal';

const InviteToJoin = () => {

    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div>
            <div className="col-lg-12 text-center py-100px">
                <p className="font-size-24px mb-0">Can’t find the coach you’re looking for?</p>

                <p className='font-size-24px'>
                    <Button text="Invite them to join Pro-AthElite" onClick={handleShow} type="button" variant="link" className="text-lg" />
                </p>
            </div>

            <InviteModal show={show} handleClose={handleClose} handleShow={handleShow} />

        </div>
    );
};

export default InviteToJoin;