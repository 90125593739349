import React, { useEffect, useState } from 'react';
import BackButton from '../../atoms/Button/BackButton';
import Button from '../../atoms/Button/Index';
import InputField from '../../atoms/Input/Index';
import endPoint from '../../config/endPoints';
import axiosWrapper from '../../config/axiosWrapper';
import Spinner from '../../atoms/Spinner';
import { useParams } from 'react-router-dom';
import Rating from '../../atoms/Rating/index';
import placholder from '../../assets/img/placeholder.svg';
import TrainerSearchFilter from '../../organism/SearchFilter/TrainerSearchFilter';
import InviteToJoin from '../../molecules/InviteToJoin';
import styled from 'styled-components';
import Pagination from '../../Hooks/Pagination';

let PageSize = 10;
const PER_PAGE = 1;

const MixedMartialArts = () => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [listGyms, setListGyms] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [haveFeatured, setHaveFeatured] = useState(false);

  const Separator = styled.hr`
    border: 1px solid #00a6fb;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
`;

  const handleSubmit = async (e) => {
    e?.preventDefault();
    let result = await axiosWrapper.get(`${endPoint.host}/trainers?subcategory=${id}&search=${searchText}&limit=10`, endPoint.header);
    if (result.success) {
      if (result.data.trainers.length > 0) {
        modifyData(result.data.trainers);
        // setListGyms(result.data.trainers);
        setTotalRecords(result.data.total);
        setLoading(false);
      }
      else {
        setListGyms([]);
        setLoadingMessage("No coaches found");
        setTotalRecords(result.data.total);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (searchText === '' || searchText === undefined) {
      fetchEvents();
      const timer = setTimeout(() => {
        handleSubmit();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [searchText])

  const featuredExists = (data) => {
    return data.some((item) => item.isFeatured === true);
  }

  const fetchEvents = async (skip = 0) => {
    let result = await axiosWrapper.get(`${endPoint.host}/trainers?subcategory=${id}&skip=${skip}&search=${searchText}`, endPoint.header);
    if (result.success) {

      modifyData(result.data.trainers);


      setTotalRecords(result.data.total);
      setLoading(false);
      return result.data.trainers;
    }
  };

  React.useMemo(async () => {
    let data = await fetchEvents((currentPage - 1) * PER_PAGE);
    modifyData(data);
    window.scrollTo(0, 0);
    return data;
  }, [currentPage]);

  const modifyData = async (trainers) => {
    if (trainers.length) {
      for (const item of trainers) {
        item.sumAvailabilityRating = 0;
        item.sumProfessionalismRating = 0;
        item.sumValueMoneyRating = 0;
        item.sumSupportRating = 0;
        item.sumResponseTimeRating = 0
        if (item?.review?.length) {
          item.review.forEach(ele => {
            item.sumAvailabilityRating += ele.availabilityRating;
            item.sumProfessionalismRating += ele.professionalismRating;
            item.sumValueMoneyRating += ele.valueMoneyRating;
            item.sumSupportRating += ele.supportRating;
            item.sumResponseTimeRating += ele.responseTimeRating;
          });
        } else {
          item.avgRating = 0;
        }
        item.avgRating = Number((Number(item.sumAvailabilityRating + item.sumProfessionalismRating + item.sumValueMoneyRating + item.sumSupportRating + item.sumResponseTimeRating) / 5).toFixed())
      }
      setListGyms(trainers);
      const countFeatured = featuredExists(trainers)
      setHaveFeatured(countFeatured);
      setLoading(false);
    } else {
      setHaveFeatured(false);
      setListGyms(trainers);
      setLoading(false);
    }
  };

  const updateGymList = (data, loading, total = 0) => {
    if (data.length > 0) {
      setListGyms(data);
      setTotalRecords(total);
      setListGyms(data);
      setLoading(loading);
      setLoadingMessage(null);
    }
    else {
      setListGyms(data);
      setTotalRecords(total);
      setListGyms(data);
      setLoading(loading);
      setLoadingMessage("No coaches found");
    }
  }

  return (
    <>
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-6">
              <div className="title">
                <h3 className="heading text-center">Mixed Martial Arts</h3>
                <p className="text-center">
                  Level up your fighting skills to the next level
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <InputField type="text" name="search"
                  onChange={(e) => { setSearchText(e.target.value !== undefined ? e.target.value : '') }}
                  value={searchText} />
              </form>
            </div>
            <TrainerSearchFilter handleGymList={updateGymList} />
            <InviteToJoin />
            <div className="col-lg-12">
              <div className="row justify-content-start serviceBoxes">
                {
                  haveFeatured && <>
                    <div className="text-center mb-40px">
                      <div className="font-size-32px fw-bold color-text-primary">Featured</div>
                    </div>

                    {listGyms.length > 0 ? listGyms?.map((item, index) => {
                      return (
                        <div className={`col-lg-6 col-6 ${item.isFeatured ? 'd-flex' : 'd-none'}`} key={index}>
                          <TrainerCard item={item} />
                        </div>
                      );
                    })
                      : <></>}
                    <Separator />
                  </>
                }
                <div className='col-lg-12'>
                  <div className='row'>
                    {loading && <Spinner />}
                    {listGyms.length > 0 ? listGyms?.map((item, index) => {
                      return (
                        <div className={`col-lg-6 col-6 ${!item.isFeatured ? 'd-flex' : 'd-none'}`} key={index}>
                          <TrainerCard item={item} />
                        </div>
                      );
                    })
                      :
                      <div className="text-center">
                        <div className="font-size-32px fw-bold color-text-primary">{loadingMessage}</div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalRecords}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MixedMartialArts;

function TrainerCard({ item }) {
  return (
    <div className="card card-custom border-0 mb-4">
      <div className="card-body">
        <div className="d-flex gap-20px">
          <div className="card-custom-img">
            <img
              className="img-fluid"
              src={
                (item.profilePic && endPoint.url + item.profilePic) || placholder
              }
              alt={item.fullname}
            />
          </div>
          <div className="card-content">
            <h4 className="m-0 font-size-24px fw-bold">{item.fullname ? item.fullname : item.name}</h4>
            <p className="m-0">{item.company}</p>
            <Rating rating={item.ratingAverage} />
            <p className="m-0">
              <strong>Location:</strong> {item.location}
            </p>
            <div className="m-0 mb-3">
              <strong>Specialization:</strong>{' '}
              {item.specialties?.map((item, index) => {
                return (
                  <p className='m-0' key={index}>
                    {item.name}
                  </p>
                );
              })}
            </div>

            <Button
              link={`/trainer_profile/${item._id}`}
              variant="primary btn-pill"
              text="View Profile"
            />
          </div>
        </div>
      </div>
    </div>
  )
}