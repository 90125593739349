import React from 'react';
import BackButton from '../atoms/Button/BackButton';

const AboutScreen = () => {
  return (
    <>
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-6">
              <div className="title">
                <h3 className="heading text-center font-size-64px">About <br />Pro-Athelite</h3>
              </div>
              <div>
                <p>A little about me and how I founded what is now Pro-Athelite. For many years I’ve been involved
                  in the fitness industry.</p>
                <p>During my late teenage years I found a passion within MMA and cage fighting. This grew into more
                  weight training in the gym.</p>
                <p>During the competitive bodybuilding season of 2022 I began to watch multiple shows across
                  different federations. The idea then came to light; there is no single place to find a coach as
                  an athlete! Along side a place for fitness events to be showcased all in one place. Everyone who
                  I saw on stage or bumped into in the audience most commonly found their coach through word of
                  mouth via others or social media, mainly Instagram platform. It occurred to me how difficult it
                  must be to research the ideal coach. Everyone as individuals require different needs when it
                  comes to a coach as to what they struggle with or need extra support with. That applies to a
                  coach also in that they all have unique specialities and prefer certain clients. </p>
                <p>I thought about how simple life could be if you could have a one stop shop to find and read real
                  life feedback from current clients. Along side having fitness events across the industry
                  advertised in one place.</p>
                <p>I personally know the importance of having someone on hand who understands you as a client and a
                  person who is best to push you to reach your full ability.</p>
                <p>As the weeks went on and I continued to attend multiple shows, it was then that Pro-Athelite was
                  born. Speaking to numerous athletes, coaches and brands in order to find out peoples opinions on
                  what they would like too see as a member, everything started too come into place.</p>
                <p>The mass feedback was that it would be extremely beneficial to all! Atheletes could look into a
                  coach that is tailored to them while coaches could further advertise their brand due to honest
                  reviews about themselves and quality advertising profiles that showcase there hard work.</p>
                <p>We all know that often people are drawn in by someone’s presentation, transformation photos,
                  number of followers and likes and somehow trust them that they can coach anyone! Within the
                  industry there is both good and bad coaches some of which are hidden in the shadows yet amazing
                  results are being missed simply due to poor marketing or no confidence in themselves.</p>
                <p>So here we are!!!</p>
                <p>Pro-Athelite stands for honest reviews giving clarity in the given sport, along side showcasing
                  the best in the industry.
                </p><p><b>Pro-Athelite</b></p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutScreen;
