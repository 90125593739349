import moment from "moment";

let formatDate = (date, format) => {
    let newDate;
    if (format !== 'ago') {
        newDate = moment.utc(date).format(format);
    } else {
        let Updatedate = moment(date);
        newDate = moment.utc(Updatedate).fromNow();
    }
    return newDate
}

export default formatDate;