import React, { useEffect, useState } from 'react';
import BackButton from '../../atoms/Button/BackButton';
import Button from '../../atoms/Button/Index';
import InputField from '../../atoms/Input/Index';
import endPoint from '../../config/endPoints';
import axiosWrapper from '../../config/axiosWrapper';
import Spinner from '../../atoms/Spinner';
import Rating from '../../atoms/Rating/index';
import placholder from '../../assets/img/placeholder.svg';
import styled, { css } from 'styled-components';
import Cta from '../../atoms/Cta';
import formatDate from '../../config/formateDate';


const Favorite = () => {
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [favoriteGyms, setFavoriteGyms] = useState([]);
  const [favoriteTrainers, setFavoriteTrainers] = useState([]);
  const [favoriteEvents, setFavoriteEvents] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const categories = [
    {
      name: 'trainers'
    },
    {
      name: 'gym'
    },
    {
      name: 'event'
    }
  ]

  useEffect(() => {
    if (searchText === "") {
      if (currentTab === 0) getFavoriteTrainers();
      if (currentTab === 1) getFavoriteGym();
      if (currentTab === 2) getFavoriteEvents();
    }
  }, [searchText])

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result = await axiosWrapper.get(
      `${endPoint.host}/${categories[currentTab].name}/favorite?search=${searchText}&limit=10`,
      endPoint.header
    );

    if (result.success) {
      setLoading(false);
      if (currentTab === 0) setFavoriteTrainers(result.data.users);
      if (currentTab === 1) setFavoriteGyms(result.data.gyms);
      if (currentTab === 2) setFavoriteEvents(result.data.events);
    }
  };

  useEffect(() => {
    if (currentTab === 0) getFavoriteTrainers();
    if (currentTab === 1) getFavoriteGym();
    if (currentTab === 2) getFavoriteEvents();
  }, [currentTab])

  const getFavoriteGym = () => {
    setLoading(true);
    axiosWrapper.get(`${endPoint.host}/gym/favorite`, endPoint.header)
      .then(result => {
        if (result.success) {
          setFavoriteGyms(result.data.gyms);
          setLoading(false);
        }
      });
  }

  const getFavoriteTrainers = () => {
    setLoading(true);
    axiosWrapper.get(`${endPoint.host}/trainers/favorite`, endPoint.header)
      .then(result => {
        if (result.success) {
          setFavoriteTrainers(result.data.users);
          setLoading(false);
        }
      });
  }

  const getFavoriteEvents = () => {
    setLoading(true);
    axiosWrapper.get(`${endPoint.host}/event/favorite`, endPoint.header)
      .then(result => {
        if (result.success) {
          setFavoriteEvents(result.data.events);
          setLoading(false);
        }
      });
  }

  const Tabs = () => {
    return (
      <>
        <ul className="nav nav-tabs justify-content-center mb-3" id="ex1" role="tablist">
          <li className="nav-item start" role="presentation">
            <Button text="Coaches" className="nav-link" variant={`${currentTab === 0 ? 'primary' : ''}`} onClick={() => setCurrentTab(0)} />
          </li>
          <li className="nav-item middle" role="presentation">
            <Button text="Gyms" className="nav-link" variant={`${currentTab === 1 ? 'primary' : ''}`} onClick={() => setCurrentTab(1)} />
          </li>
          <li className="nav-item end" role="presentation">
            <Button text="Events" className="nav-link" variant={`${currentTab === 2 ? 'primary' : ''}`} onClick={() => setCurrentTab(2)} />
          </li>
        </ul>

        <div className="tab-content" id="ex1-content">
          {currentTab === 0 ?
            <div
              className="tab-pane fade show active"
              id="ex1-tabs-1"
              role="tabpanel"
              aria-labelledby="ex1-tab-1"
            >
              {loading ? <Spinner /> : <RenderCoaches />}
            </div>
            : currentTab === 1 ?
              <div className="tab-pane fade show active" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                {loading ? <Spinner /> : <RenderGyms />}
              </div>

              : currentTab === 2 &&
              <div className="tab-pane fade show active" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                {loading ? <Spinner /> : <RenderEvents />}
              </div>
          }
        </div>
      </>
    )
  }

  const RenderCoaches = () => {
    return (
      <div className="row serviceBoxes">
        {favoriteTrainers.length > 0 ? favoriteTrainers?.map((item, index) => {
          return (
            <div key={index} className="col-lg-6">
              <div className="card card-custom border-0">
                <div className="card-body">
                  <div className="d-flex gap-20px flex-wrap">
                    <div className="card-custom-img">
                      <img
                        className="img-fluid"
                        style={{ width: '200px' }}
                        src={
                          (item.profilePic && endPoint.url + item.profilePic) || placholder
                        }
                        alt={item.fullname}
                      />
                    </div>
                    <div className="card-content">
                      <h4 className="m-0 font-size-24px fw-bold">{item.fullname ? item.fullname : item.name}</h4>
                      <Rating rating={item?.ratingCount} />
                      <p className="m-0">
                        <strong>Location:</strong> {item.location}
                      </p>
                      <div className="m-0 mb-3">
                        <strong>Specialization:</strong>{' '}
                        {item.specialties?.map((item, index) => {
                          return (
                            <p className='m-0' key={index}>
                              {item.name}
                            </p>
                          );
                        })}
                      </div>

                      <Button
                        link={`/trainer_profile/${item._id}`}
                        variant="primary btn-pill"
                        text="View Profile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }) :
          <div className="col-lg-12">
            <div className="card card-custom border-0">
              <div className="card-body">
                <div className="d-flex gap-20px flex-wrap">
                  <div className="card-content">
                    <h4 className="m-0 font-size-24px fw-bold">No Favorite Coaches</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  const RenderGyms = () => {
    return (
      <div className="row serviceBoxes">
        {favoriteGyms.length > 0 ? favoriteGyms?.map((item, index) => {
          return (
            <div key={index} className="col-lg-6">
              <div className="card card-custom border-0">
                <div className="card-body">
                  <div className="d-flex gap-20px flex-wrap">
                    <div className="card-custom-img">
                      <img
                        className="img-fluid"
                        style={{ width: '200px' }}
                        src={
                          (item.image && endPoint.url + item.image) || placholder
                        }
                        alt={item.fullname}
                      />
                    </div>
                    <div className="card-content">
                      <h4 className="m-0 font-size-24px fw-bold">{item.fullname ? item.fullname : item.name}</h4>
                      <Rating rating={item?.ratingCount} />
                      <p className="m-0">
                        <strong>Location:</strong> {item.location}
                      </p>
                      <div className="m-0 mb-3">
                        <strong>Specialization:</strong>{' '}
                        {item.features?.map((item, index) => {
                          return (
                            <p className='m-0' key={index}>
                              {item}
                            </p>
                          );
                        })}
                      </div>

                      <Button
                        link={`/gym/${item._id}`}
                        variant="primary btn-pill"
                        text="View Gym"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
          :
          <div className="col-lg-12">
            <div className="card card-custom border-0">
              <div className="card-body">
                <div className="d-flex gap-20px flex-wrap">
                  <div className="card-content">
                    <h4 className="m-0 font-size-24px fw-bold">No Favorite Gyms</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  const RenderEvents = () => {
    return (
      <div className="row serviceBoxes">
        {favoriteEvents.length > 0 ? favoriteEvents?.map((item, index) => {
          return (
            <div className='col-lg-6'>
              <Cta
                eventName={item.name}
                icon={endPoint.url + item.image}
                region={item.city}
                date={formatDate(item.startTime, "MMMM Do YYYY, h:mm:ss a")}
                btnText="See details"
                link={`/event/${item._id}`}
              />
            </div>
          )
        })
          :
          <div className="col-lg-12">
            <div className="card card-custom border-0">
              <div className="card-body">
                <div className="d-flex gap-20px flex-wrap">
                  <div className="card-content">
                    <h4 className="m-0 font-size-24px fw-bold">No Favorite Events</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  useEffect(() => {
    getFavoriteGym();
    getFavoriteTrainers();
    getFavoriteEvents();
  }, []);
  return (
    <>
      <section className='pt-40px'>
        <div className="container">
          <div className="row justify-content-center">
            <BackButton />
            <div className="col-lg-6">
              <div className="title">
                <h3 className="heading text-center">Favorites</h3>
                <p className="text-center">
                  A brief description of the category and what they can do.
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
              <form onSubmit={handleSearch}>
                <InputField type="text" name="search"
                  onChange={(e) => { setSearchText(e.target.value !== undefined ? e.target.value : '') }}
                  value={searchText} />
              </form>
            </div>

            <div className="col-lg-12">
              <Tabs />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Favorite;