import filledStar from '../assets/img/icons/ic-filled-star.svg';
import Star from '../assets/img/icons/ic-star.svg';
import React from 'react';

const RatingComponent = ({ ratingCount }) => {
    return (
        <>
            {
                ratingCount === 0 ? (
                    <span>
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                    </span>
                ) : ratingCount === 1 ? (
                    <span>
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                    </span>
                ) : ratingCount === 2 ? (
                    <span>
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                    </span>
                ) : ratingCount === 3 ? (
                    <span>
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                    </span>
                ) : ratingCount === 4 ? (
                    <span>
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={Star} />
                    </span>
                ) : ratingCount === 5 ? (
                    <span>
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                        <img alt="rating" src={filledStar} />
                    </span>
                ) : (
                    <span>
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                        <img alt="rating" src={Star} />
                    </span>
                )
            }
        </>
    );
};

export default RatingComponent;