import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Index';
import Snackbar from '../../atoms/Snackbar/Index';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import { userTypes } from '../profile';


const Individual = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [listSubscriptions, setListSubscription] = useState([]);

  const handleSubmit = async (item) => {

    try {
      if (item.stripeMembershipId === "") {
        let result = await axiosWrapper.post(`${endPoint.host}/subscription/live/setup`, {
          subscriptionId: item._id,
          userType: userTypes.INDIVIDUAL
        },
          {
            Authorization: localStorage.getItem('token'),
          }
        );
        if (result.success) {
          localStorage.setItem('subscription', JSON.stringify(result.data?.userSubscription?.subscriptionId));
          navigate("/");
        }
      } else {
        localStorage.setItem('newUserType', userTypes.INDIVIDUAL);
        navigate(`/stripeCardUI/${item._id}`)
      }

    } catch (error) {
      setError(true);
      setErrorMsg(error.response.data.message);
    }
  };
  const fetchSubscriptions = async () => {
    let data = await axiosWrapper.get(`${endPoint.host}/subscriptions?usertype=individual`);

    if (data.success) {
      console.log(data.data.subscriptions)
      setListSubscription(data.data.subscriptions);
    }
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);
  return (
    <>
      {error ? <Snackbar message={errorMsg} type="alert" onClick={() => setError(false)} /> : ''}
      <section className="register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title text-center">
                <h3 className="heading my-40px">Choose your plan</h3>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  {listSubscriptions?.map((item, index) => {
                    return (
                      <div className="col-lg-6" key={index}>
                        <div
                          className={`card card-plan card-${item.price === 0 ? 'left' : 'right'}`}
                        >
                          <div className="card-body">
                            <div className="text-center mb-40px">
                              <h3 className="fw-bold font-size-32px">{item.name}</h3>
                              <p className="font-size-24px">{item.price === 0 ? "Free" : `£ ${item.price} / month`}</p>
                            </div>
                            <ul className="planServiceList">
                              <li>
                                <span>
                                  <i className="fi fi-rr-check" />
                                </span>{' '}
                                Basic profile
                              </li>
                              <li>
                                <span>
                                  <i className="fi fi-rr-check" />
                                </span>{' '}
                                Viewing &amp; writing reviews
                              </li>
                              <li>
                                <span>
                                  <i className={`fi ${item.price === 0 ? 'fi-rr-cross-small' : 'fi-rr-check'}`} />
                                </span>
                                {item.price === 0 ? 'No access to events' : 'Access to events'}

                              </li>
                              <li>
                                <span>
                                  <i className={`fi ${item.price === 0 ? 'fi-rr-cross-small' : 'fi-rr-check'}`} />
                                </span>{' '}
                                {item.price === 0 ? "No special discounts" : "Special discounts"}
                              </li>
                              <li>
                                <span>
                                  <i className={`fi ${item.price === 0 ? 'fi-rr-cross-small' : 'fi-rr-check'}`} />
                                </span>
                                {item.price === 0 ? "No gyms list & reviews" : "Gyms list & reviews"}
                              </li>
                            </ul>
                            <div className="text-center">
                              <Button
                                text="Choose"
                                variant={
                                  item.price === 0 ? `t btn-outline-dark` : 'primary rounded-0'
                                }
                                onClick={() => handleSubmit(item)}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Individual;
