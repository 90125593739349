import React from 'react';
import instagramIcon from '../../assets/img/Instagram.svg';
import fbIcon from '../../assets/img/facebook.svg';
import twitterIcon from '../../assets/img/Twitter.svg';
import tiktokLogo from "../../assets/img/tiktok.svg";
import instaFilled from '../../assets/img/ic-insta-filled.svg';
import fbFilled from '../../assets/img/ic-fb-filled.svg';
import twitterFilled from '../../assets/img/ic-twitter-filled.svg';
import tiktokFilled from '../../assets/img/ic-tiktok-filled.svg';

const SocialIcons = ({ fill }) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center text-center gap-25px">
                <a target="/blank" href="https://www.instagram.com/pro_athelite/">
                    <img src={fill ? instaFilled : instagramIcon} alt="Instagram" />
                </a>
                <a target="/blank" href="https://www.facebook.com/ProAthelite"><img src={fill ? fbFilled : fbIcon} alt="Facebook" /></a>
                <a target="/blank" href="https://twitter.com/ProAthelite_LTD" ><img src={fill ? twitterFilled : twitterIcon} alt="Twitter" /></a>
                <a target="/blank" href="https://www.tiktok.com/@proathelite"><img src={fill ? tiktokFilled : tiktokLogo} alt="TikTok" /></a>
            </div>
        </>
    );
};

export default SocialIcons;