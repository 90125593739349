import React, { useState } from 'react';
import Button from '../../atoms/Button/Index';
import ReactStars from 'react-rating-stars-component';
import axiosWrapper from '../../config/axiosWrapper';
import endPoint from '../../config/endPoints';
import { useParams } from 'react-router-dom';
import Snackbar from '../../atoms/Snackbar/Index';
import toaster from '../../config/toaster';
import { ToastContainer } from 'react-toastify';

const SubmitReview = ({ handleVisibility }) => {
  const { id } = useParams();


  const [msgType, setMsgType] = useState('');
  const [msg, setMsg] = useState('');
  const [alert, setAlert] = useState(false);
  const [valueMoneyRating, setValueMoneyRating] = useState(0);
  const [equipmentRating, setEquipmentRating] = useState(0);
  const [hygenicRating, setHygenicRating] = useState(0);
  const [facilitiesRating, setFacilitiesRating] = useState(0);
  const [availabilityRating, setAvailabilityRating] = useState(0);
  const [comment, setComment] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (comment === '') {
      setMsgType('alert');
      setAlert(true);
      setMsg('Comment field cannot be empty');
      return false;
    }
    let url = endPoint.host +'/gym/' + id + '/review';

    let result = await axiosWrapper.post(
      url,
      {
        valueMoneyRating,
        equipmentRating,
        hygenicRating,
        facilitiesRating,
        availabilityRating,
        comment,
      },
      endPoint.header
    );

    if (result.success) {

      setValueMoneyRating(0);
      setEquipmentRating(0);
      setHygenicRating(0);
      setFacilitiesRating(0);
      setAvailabilityRating(0);
      setComment('');
      toaster("success", result.message)
      setTimeout(() => {
        // handleVisibility();
      }, 1000);
    }
  };
  return (
    <>
      <ToastContainer />

      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="mb-3">Value Money Rating</label>
            <ReactStars
              count={5}
              onChange={(newRating) => setValueMoneyRating(newRating)}
              size={30}
              activeColor="#ffd700"
            />
          </div>
          <div className="form-group">
            <label className="mb-3">Equipment Rating</label>
            <ReactStars
              count={5}
              onChange={(newRating) => setEquipmentRating(newRating)}
              size={30}
              activeColor="#ffd700"
            />
          </div>
          <div className="form-group">
            <label className="mb-3">Hygenic Rating</label>
            <ReactStars
              count={5}
              onChange={(newRating) => setHygenicRating(newRating)}
              size={30}
              activeColor="#ffd700"
            />
          </div>
          <div className="form-group">
            <label className="mb-3">Facilities Rating</label>
            <ReactStars
              count={5}
              onChange={(newRating) => setFacilitiesRating(newRating)}
              size={30}
              activeColor="#ffd700"
            />
          </div>
          <div className="form-group">
            <label className="mb-3">Availability Rating</label>

            <ReactStars
              count={5}
              onChange={(newRating) => setAvailabilityRating(newRating)}
              size={30}
              activeColor="#ffd700"
            />
          </div>
          <div className="form-group">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Comment..."
              className="form-control"
            />
          </div>
          <div className="form-group">
            <Button variant="primary btn-pill" text="Submit" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default SubmitReview;
