import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='container'>
            <div className='row py-5'>
                <div className='col-12 py-3 text-center'>
                    <h2><strong>Privacy Policy</strong></h2>
                </div>
                <div className='col-12'>

                    <h3>(“us”, “we”, or “our”) operates the Pro-Athelite (hereinafter referred to as the “Service”).</h3>
                    <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                    <br />
                    <h2>Definitions</h2>
                    <br />
                    <h4>Personal Data</h4>
                    <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
                    <h4>Usage Data</h4>
                    <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                    <h4>Cookies</h4>
                    <p>Cookies are small files stored on your device (computer or mobile device).</p>
                    <h4>Data Controller</h4>
                    <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
                    <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
                    <h4>Data Processors (or Service Providers)</h4>
                    <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.</p>
                    <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
                    <h4>Data Subject (or User)</h4>
                    <p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.</p>
                    <h4>Information Collection and Use</h4>
                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                    <h4>Personal Data</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                    <ul>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                        <li>Cookies and Usage Data</li>
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default PrivacyPolicy;