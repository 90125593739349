import React from 'react';
import { useNavigate } from 'react-router-dom';
import unauthorized from '../assets/img/not-authorized.svg';

const userTypes = {
    INDIVIDUAL: 'INDIVIDUAL',
    COACH: 'COACH',
}

const NotAllowed = () => {
    const navigate = useNavigate();
    const { usertype } = localStorage.getItem('subscription') && JSON.parse(localStorage.getItem('subscription'));

    const onNavigate = () => {
        if (usertype === userTypes.INDIVIDUAL) {
            navigate('/subscription-individual');
        } else if (usertype === userTypes.COACH) {
            navigate('/subscription-coach');
        }
    }

    return (
        <div>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img src={unauthorized} className="img-fluid mb-4" width="250px" alt='unauthorized icon' />
                            <h2 className='font-weight-700'>Unauthorized</h2>
                            <div>
                                <p className='mb-0'>You are not authorized to view this page</p>
                                <p>Please upgrade your subscription.</p>
                                <button type="button" className="btn px-5 btn-pill color-primary text-white" onClick={onNavigate}>
                                    Upgrade Subscription
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NotAllowed;