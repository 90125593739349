import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../../organism/Header/Index';
import ProtectedRoute from '../../ProtectedRoute';
import AboutScreen from '../../screens/AboutScreen';
import LoginScreen from '../../screens/auth/LoginScreen';
import RegisterScreen from '../../screens/auth/RegisterScreen';
import BuildEliteScreen from '../../screens/BuildEliteScreen';
import Competition from '../../screens/subCategories/Competition';
import ContactScreen from '../../screens/ContactScreen';
import EventDetails from '../../screens/EventDetails';
import EventsScreen from '../../screens/EventsScreen';
import FightEliteScreen from '../../screens/FightEliteScreen';

import FooterScreen from '../../screens/footerScreen';
import GymDetails from '../../screens/Gym/GymDetails';
import GymEliteScreen from '../../screens/GymEliteScreen';
import HomeScreen from '../../screens/HomeScreen';
import Profile from '../../screens/profile';
import EditProfile from '../../screens/profile/EditProfile';
import Coach from '../../screens/subscription/Coach';
import Individual from '../../screens/subscription/Individual';
import LifeStyle from '../../screens/subCategories/LifeStyle';
import PhotoshootPrep from '../../screens/subCategories/PhotoshootPrep';
import Boxing from '../../screens/subCategories/Boxing';
import MixedMartialArts from '../../screens/subCategories/MixedMartialArts';
import TrainerProfile from '../../screens/trainer/Index';
import ForgotScreen from '../../screens/auth/ForgotScreen';
import ResetPasswordScreen from '../../screens/auth/ResetPasswordScreen';
import TermsAndCondition from '../../screens/TermsAndCondition';
import PrivacyPolicy from '../../screens/PrivacyPolicy';
import NotForLoggedIn from '../../NotForLoggedIn';
import NotAllowed from '../../screens/NotAllowed';
import UnAuthorizedRoute from '../../UnAuthorizedRoute';
import StripeSplitCard from '../../stipecomponents/StripeSplitCard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Favorite from '../../screens/Favorites';
import AddEvent from '../../screens/Events/AddEvent';
import AddGym from '../../screens/Gym/AddGym';


const Index = () => {



  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" exact element={<HomeScreen />} />
        <Route path="/register" element={<NotForLoggedIn><RegisterScreen /></NotForLoggedIn>} />
        <Route path="/forgot" element={<NotForLoggedIn><ForgotScreen /></NotForLoggedIn>} />
        <Route path="/reset" element={<ResetPasswordScreen />} />
        <Route path="/login" element={<NotForLoggedIn><LoginScreen /></NotForLoggedIn>} />
        <Route path="/buildelite" element={<BuildEliteScreen />} />
        <Route path="/fightelite" element={<FightEliteScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/gymelite"
          element={
            <ProtectedRoute>
              <UnAuthorizedRoute>
                <GymEliteScreen />
              </UnAuthorizedRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <UnAuthorizedRoute>
                <EventsScreen />
              </UnAuthorizedRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="/subscription-individual"
          element={
            <ProtectedRoute>
              <Individual />
            </ProtectedRoute>
          }
        />

        <Route
          path="/subscription-coach"
          element={
            <ProtectedRoute>
              <Coach />
            </ProtectedRoute>
          }
        />

        <Route
          path="/event/:id"
          element={
            <ProtectedRoute>
              <UnAuthorizedRoute>
                <EventDetails />
              </UnAuthorizedRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="/event/add"
          element={
            <ProtectedRoute>
              <UnAuthorizedRoute>
                <AddEvent />
              </UnAuthorizedRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="/gym/:id"
          element={
            <ProtectedRoute>
              <UnAuthorizedRoute>
                <GymDetails />
              </UnAuthorizedRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="/gym/add"
          element={
            <ProtectedRoute>
              <UnAuthorizedRoute>
                <AddGym />
              </UnAuthorizedRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/edit"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/competition/:id"
          element={
            <ProtectedRoute>
              <Competition />
            </ProtectedRoute>
          }
        />
        <Route
          path="/favorites"
          element={
            <ProtectedRoute>
              <Favorite />
            </ProtectedRoute>
          }
        />
        <Route
          path="/lifestyle/:id"
          element={
            <ProtectedRoute>
              <LifeStyle />
            </ProtectedRoute>
          }
        />
        <Route
          path="/photoshootprep/:id"
          element={
            <ProtectedRoute>
              <PhotoshootPrep />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mixedmartialarts/:id"
          element={
            <ProtectedRoute>
              <MixedMartialArts />
            </ProtectedRoute>
          }
        />

        <Route
          path="/boxing/:id"
          element={
            <ProtectedRoute>
              <Boxing />
            </ProtectedRoute>
          }
        />

        <Route
          path="/trainer_profile/:id"
          element={
            <ProtectedRoute>
              <TrainerProfile />
            </ProtectedRoute>
          }
        />

        <Route path='/not-authorized' element={<NotAllowed />} />


        <Route path='/stripeCardUI/:id' element={
          <Elements stripe={stripePromise} >
            <StripeSplitCard />
          </Elements>
        } />
      </Routes>

      <FooterScreen />
    </div>
  );
};
//Stripe Keys
// Test Publish key
// const stripePromise = loadStripe('pk_test_51M42qMCNMpR8LFEKk2A0KKT9t5t8sP118b3Ioi3Uj0jeyTr5KC4F2056PxkWTu9mDF0wE9dEvynh6LyDaPfLXjA500i3y8LmEF');
// Live Publish key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API);

export default Index;
